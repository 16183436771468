import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import "assets/vendors/style";
import configureStore, { history } from "./appRedux/store";
import App from "./containers/App/index";

export const store = configureStore();

const NextApp = () => {
  useEffect(() => {
    let link = document.createElement("link");
    link.type = "text/css";
    link.rel = "stylesheet";
    link.href = "/css/orange.css";
    link.className = "gx-style";
    document.body.appendChild(link);
  }, []);
  return (
    <Provider store={store}>
      <>
        <BrowserRouter history={history}>
          <Switch>
            <Route path="/" component={App} />
          </Switch>
        </BrowserRouter>
      </>
    </Provider>
  );
};

export default NextApp;
