import React, { useState, useEffect } from "react";
import { Button, Input, Form, message } from "antd";

import { Link } from "react-router-dom";
import withLoaderButton from "../../hocs/StyledButton";

const FormItem = Form.Item;
const ForgotPassword = () => {
  const [isLoading] = useState(false);
  const [isNullField] = useState(false);
  const [errorsList] = useState([]);
  const LoaderButton = withLoaderButton(Button);
  const [form] = Form.useForm();
  const [isSuccess] = useState(false);
  const [email] = useState("");

  useEffect(() => {
    form.setFields(errorsList);
    if (isNullField) message.error("Ocurrió un error al enviar el correo");
  }, [errorsList, isNullField]);

  const onFinish = async (values) => {};

  return (
    <div className="gx-login-container">
      <div className="gx-login-content">
        <div className="gx-login-header">
          <img
            src={require("assets/images/logo-white.png")}
            alt="wieldy"
            title="wieldy"
          />
        </div>
        <div className="gx-mb-4">
          <h2>
            {isSuccess ? "Correo enviado exitosamente" : "Recuperar contraseña"}
          </h2>
        </div>
        {isSuccess ? (
          <div className="gx-pb-3">
            Hemos enviado un correo electrónico a{" "}
            <span className="gx-text-primary">{email}</span> con un enlace para
            recuperar tu contraseña. Si no encuentras el correo en tu bandeja
            principal, por favor revisar la bandeja de spam.
          </div>
        ) : (
          <Form
            form={form}
            layout="vertical"
            onFinish={(values) => onFinish(values)}
            className="gx-login-form gx-form-row0"
          >
            <FormItem
              name="user"
              rules={[
                {
                  type: "email",
                  message: "El correo electrónico no es valido!",
                },
                {
                  required: true,
                  message: "Introduzca su correo electrónico!",
                },
              ]}
            >
              <Input
                autoComplete="none"
                type="email"
                placeholder="Ingrese su correo electrónico"
              />
            </FormItem>

            <FormItem className="gx-text-right gx-m-0 ">
              <Button type="link">
                <Link to="/signin" className="gx-ml-2">
                  Cancelar
                </Link>
              </Button>

              <LoaderButton isLoading={isLoading}>Enviar</LoaderButton>
            </FormItem>
          </Form>
        )}
      </div>
    </div>
  );
};

export default ForgotPassword;
