import React, { useEffect, useState } from "react";
import { Card, Table, Button, Col, Row, DatePicker, Pagination } from "antd";
import ChatUserTable from "./ChatUserTable";
import locale from "antd/es/date-picker/locale/es_ES";
import "moment/locale/es";
import moment from "moment";
import { useDownload } from "../../../services/downloadCsv";
const { RangePicker } = DatePicker;

const SimpleTable = ({
  columns,
  data,
  buttonTitle,
  onClickModal,
  isDateFilter,
  isRolFilter,
  expandable,
  loading = false,
  tableToDownload = "",
  paginationData = {},
  onPagination = () => {},
}) => {
  const { download } = useDownload();
  const dayFromDefault = moment("2021-04-01");
  const dayToDefault = moment(Date.now()).add(1, "d");
  const [contactRequestData, setContactRequestData] = useState([]);
  const [fromDate, setFromDate] = useState(dayFromDefault);
  const [toDate, setToDate] = useState(dayToDefault);
  useEffect(() => {
    setContactRequestData(data);
  }, [data]);
  useEffect(() => {
    handleOnChangeFilterByDate(fromDate, toDate);
  }, [fromDate, toDate]);

  useEffect(() => {
    setContactRequestData(data);
  }, [data]);
  const handleOnChangeFilterByDate = (from, to) => {
    const filterByDate = data.filter((dataElem) => {
      return moment(dataElem.date).isBetween(from, to, undefined, "[]");
    });
    if (!from && !to) {
      setFromDate(dayFromDefault);
      setToDate(dayToDefault);
    } else setContactRequestData(filterByDate);
  };

  const downloadCsv = () => {
    download({ endpoint: tableToDownload });
  };

  return (
    <Card>
      <Row>
        <Col
          className="gx-mb-4"
          xl={isRolFilter ? 5 : 7}
          lg={isRolFilter ? 6 : 7}
          md={isRolFilter ? 8 : 16}
          sm={isRolFilter ? 8 : 16}
          xs={isRolFilter ? 12 : 24}
        >
          {isDateFilter && (
            <RangePicker
              className="gx-w-100"
              locale={locale}
              defaultValue={[fromDate, toDate]}
              onChange={(_, [from, to]) => {
                setFromDate(from);
                setToDate(to);
              }}
            />
          )}
        </Col>

        <Col
          // className="gx-mb-4"
          xl={isRolFilter ? 19 : 17}
          lg={isRolFilter ? 18 : 17}
          md={isRolFilter ? 16 : 8}
          sm={isRolFilter ? 16 : 8}
          xs={isRolFilter ? 12 : 24}
          className="gx-d-flex"
        >
          <div style={{ marginLeft: "auto" }}>
            {tableToDownload && (
              <Button
                type="primary"
                style={{ marginLeft: "auto" }}
                onClick={downloadCsv}
              >
                Descargar CSV
              </Button>
            )}
            <Button
              type="primary"
              style={{ marginLeft: "auto" }}
              onClick={onClickModal}
            >
              {buttonTitle}
            </Button>
          </div>
        </Col>
      </Row>

      <Table
        className="gx-table-responsive components-table-demo-nested"
        columns={columns}
        dataSource={contactRequestData}
        loading={loading}
        expandable={
          expandable
            ? {
                expandedRowRender: (record) =>
                  record.user ? <ChatUserTable data={record.user} /> : null,
                rowExpandable: (record) => record.user,
              }
            : undefined
        }
        scroll={{ x: window.innerWidth < 1200 ? 1200 : false }}
        pagination={!paginationData.current}
      />
      {paginationData.current && <>
        <Pagination
          current={paginationData.current}
          pageSize={paginationData.perPage}
          total={paginationData.total}
          onChange={(page) => onPagination(page)}
          style={{textAlign: "end", marginTop: "10px"}}
          showSizeChanger={false}
        />
      </>}
    </Card>
  );
};

export default SimpleTable;
