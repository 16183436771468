import React, { useEffect, useState } from "react";
import { 
  Card, 
  Table, 
  Pagination, 
  Tooltip, 
  Col, 
  DatePicker,
  Button,
} from "antd";
import "moment/locale/es";
import { useLocation } from 'react-router-dom';
import { dateFormat } from "../utils/formatters";
import { EyeOutlined, DownloadOutlined } from "@ant-design/icons";
import { usePayments } from "../../context/payment/usePayments";
import PaymentDetails from "./PaymentDetails";
import UploadVoucher from "../Balances/UploadVoucher";
import { numberFormatter } from "../utils/formatters";
import { downloadFile } from "../utils/downloadFile";
import { ReactComponent as DrinkerLogo } from "../../assets/images/drinker_small_logo.svg";

const Payments = () => {
  const { 
    getPaymentByStore, 
    isLoading, 
    invoiceList, 
    pagination,
  } = usePayments();
  const [currentPage, setCurrentPage] = useState(1);
  const [tableFilters, setTableFilters] = useState({});
  const [tableData, setTableData] = useState([]);
  const [showDetails, setShowDetails] = useState(false);
  const [paymentId, setPaymentId] = useState();
  const [isVisible, setIsVisible] = useState(false);

  const location = useLocation();
  const { id : barId } = location.state;

  const { RangePicker } = DatePicker;

  const handlerDetails = (data) => {
    setPaymentId(data.id)
    setShowDetails(true);
  };

  const columns = [
    {
      title: "F. de pago",
      width: 120,
      dataIndex: "payment_date",
      key: "payment_date",
      defaultSortOrder: "descend",
      render: (text, _) => {
        return <span>{dateFormat(text)}</span>;
      },
    },
    {
      title: "Nro.",
      dataIndex: "number",
      align: "center",
      key: "number",
    },
    {
      title: "Monto en Bs",
      dataIndex: "amount_bolivares",
      align: "center",
      key: "amount_bolivares",
      render: (text) => {
        return <span>{numberFormatter(text)}</span>;
      },
    },
    {
      title: "Comprobante",
      dataIndex: "voucher",
      align: "center",
      key: "voucher",
      render: (_, record) => (
        <>
          <Tooltip title="Descargar">
            <DownloadOutlined
              style={{ fontSize: 20, cursor: "pointer" }}
              onClick={()=>downloadFile(record.voucher, 'Comprobante')}
            />
          </Tooltip> 
        </>
      ),
    },
    {
      title: "Detalle",
      dataIndex: "detail",
      align: "center",
      key: "detail",
      render: (_, record) => (
        <>
          <Tooltip title="Ver">
            <EyeOutlined
              style={{ fontSize: 20, cursor: "pointer" }}
              onClick={()=> handlerDetails(record)}
            />
          </Tooltip> 
        </>
      ),
    },
    {
      title: "Tasa de cambio",
      dataIndex: "exchange_rate",
      align: "center",
      key: "exchange_rate",
      render: (text) => {
        return <span>{numberFormatter(text)}</span>;
      },
    },
  ];

  const onPagination = (page) => {
    setCurrentPage(page);
  };

  const onChangeDate = (value) => {
    setCurrentPage(1);
    setTableFilters((prevFilter) => ({ ...prevFilter, rangeDate: value }));
  };

  const getTableData = () => {
    if (currentPage === 1){
      getPaymentByStore(barId, currentPage)
    } else {
      setCurrentPage(1);
    }
  };

  useEffect(() => {
    if(tableFilters && Object.keys(tableFilters).length > 0){
      const transformedFilters = {};
      Object.entries(tableFilters).forEach(([key, value]) => {
        if (Array.isArray(value) && value.length > 0) {
          transformedFilters[key] = value;
        } else {
          transformedFilters[key] = value;
        }
      });
      getPaymentByStore(barId, currentPage, transformedFilters);
    } else {
      getPaymentByStore(barId, currentPage)
    }
  }, [currentPage, tableFilters]);

  useEffect(()=>{
    if(invoiceList.results){
      const mappedData = invoiceList.results.map((elem)=>{
        const storeData = {
          id: elem.id,
          payment_date: elem.created_at,
          number: elem.reference_number,
          amount_bolivares: elem.amount_bolivares,
          voucher: elem.receipt,
          exchange_rate: elem.usd_exchange_rate,
          products: elem.products,
        }
        return storeData
      })
      setTableData(mappedData);
    }
  },[invoiceList])

  return (
    <Card>
      <div className=" gx-align-items-center gx-d-flex gx-justify-content-between gx-mx-0">
        <div className="gx-d-flex gx-mx-0" >
            { invoiceList.results &&
              <>
                <div className="custom-card gx-mb-3 gx-mr-4">
                  <div className="custom-card-title">{numberFormatter(invoiceList.total_unpaid_bolivares) || 0} Bs</div>
                    <div className="custom-card-title">{numberFormatter(invoiceList.total_unpaid) || 0} <DrinkerLogo height={13}/></div>
                  <div>Saldo a pagar</div>
                </div>
                <div className="custom-card gx-mb-3">
                    <div className="custom-card-title">{numberFormatter(invoiceList.total_paid_bolivares) || 0} Bs</div>
                    <div className="custom-card-title">{numberFormatter(invoiceList.total_paid) || 0} <DrinkerLogo height={13}/></div>
                    <div>Total pagado</div>
                </div>
              </>
            }
        </div>
        <Col className="gx-mb-3 gx-flex-column gx-align-items-end">
          <RangePicker 
            placeholder={["Fecha inicio", "Fecha final"]}
            style={{width: 250}}  
            onChange={onChangeDate} 
          />
          {invoiceList.total_unpaid_bolivares > 0 &&
            <Button 
              type="primary" 
              style={{width: 86}} 
              className="gx-mt-2"
              onClick={() => {
                  setIsVisible(true);
              }}
            >
              Pagar
            </Button>
          }
        </Col>
      </div>
      <Table
        className="gx-table-responsive"
        loading={isLoading}
        columns={columns}
        dataSource={tableData}
        scroll={{ x: window.innerWidth < 1200 ? 1200 : false }}
        pagination={false}
      />
      <Pagination
        current={currentPage}
        pageSize={pagination.perPage}
        total={pagination.total}
        onChange={(page) => onPagination(page)}
        style={{textAlign: "end", marginTop: "10px"}}
        showSizeChanger={false}
      />
      <PaymentDetails
        isVisible={showDetails}
        setIsVisible={setShowDetails}
        paymentId={paymentId}
        setPaymentId={setPaymentId}
      />
      <UploadVoucher
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        store={barId}
        action="create"
        withPaymentTable={true}
        getTableData={getTableData}
      />
    </Card>
  );
};

export default Payments;
