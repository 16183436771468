import { useContext } from "react";
import { payment } from "../../api/api";
import { PaymentContext } from "./payments-context";
import { stores } from "../../api/api";
import moment from "moment";

const usePayments = () => {
  const {
    isLoading,
    setIsLoading,
    modalLoading, 
    setModalLoading,
    balance,
    setBalance,
    payments,
    setPayments,
    selectedPayment,
    setSelectedPayment,
    pagination, 
    setPagination,
    invoiceList,
    setInvoiceList,
    accountList, 
    setAccountList,
    pendingPaymentData, 
    setPendingPaymentData,
    paymentDetails, 
    setPaymentDetails,
    commisions, 
    setCommissions,
  } = useContext(PaymentContext);

  const getBalances = async (page, filters) => {
    setIsLoading(true);
    const { data, error } = await stores.getBalance({
      page,
      store_name: filters ? filters.name : undefined,
      last_payment_start_date: filters ? filters.rangeDate && moment(filters.rangeDate[0]).format('YYYY-MM-DD') : undefined,
      last_payment_end_date: filters ? filters.rangeDate && moment(filters.rangeDate[1]).format('YYYY-MM-DD')  : undefined,
    });
    if (!error) {
      setPagination({
        current: page,
        perPage: 15,
        total: data.count,
      })
      setBalance({balance: [...data.results], total:data.total, exchange_rate: data.usd_ves_exchange_rate});
    }
    setIsLoading(false);
  };

  const postVoucherPayment = async (payload) => {
    setIsLoading(true);
    const { error } = await payment.postStorePayment(payload);
    if (!error) {
      getBalances();
    } else {
      setIsLoading(false);
    }
  };

  const patchVoucherPayment = async (payload) => {
    setIsLoading(true);
    const {  error } = await payment.patchStorePayment(payload);
    if (!error) {
      // const balances = [];
      // balances.push(data)
      // setBalance(balances)
    }

    setIsLoading(false);
  };

  const getPayments = async () => {
    setIsLoading(true);
    const { data, error } = await payment.getPayments();
    if (!error) {
      setPayments(data.results);
    }
    setIsLoading(false);
  };

  const getPaymentByStore = async (id, page, filters) => {
    setIsLoading(true);
    const { data, error } = await payment.getPaymentByStoreId({
      page,
      storeId: id,
      start_date: filters ? filters.rangeDate && moment(filters.rangeDate[0]).format('YYYY-MM-DD') : undefined,
      end_date: filters ? filters.rangeDate && moment(filters.rangeDate[1]).format('YYYY-MM-DD')  : undefined,
    });
    if (!error) {
      setPagination({
        current: page,
        perPage: 15,
        total: data.count,
      })
      setInvoiceList(data);
    }
    setIsLoading(false);
  };

  const getAdminAccount = async (page) => {
    setIsLoading(true);
    const { data, error } = await payment.getFundsAccount(page);
    if (!error) {
      setAccountList(data.results.filter(({name}) => name !== "Drinker" ));
    }
    setIsLoading(false);
  };

  const getAdminCommissions = async () => {
    setIsLoading(true);
    const { data, error } = await payment.getAdminCommissionsData();
    if (!error) {
      setCommissions(data);
    }
    setIsLoading(false);
  };

  const getPendingPayment = async (storeId) => {
    setModalLoading(true);
    const { data, error } = await payment.getPendingPaymentData(storeId);
    if (!error) {
      setPendingPaymentData(data);
    }
    setModalLoading(false);
  };

  const createPendingPayment = async (payload, type) => {
    setModalLoading(true);
    const { error } = await payment.createPayment(payload);
    if (!error) {
      setModalLoading(false);
      return {success: true};
    } 
    setModalLoading(false);
  };

  const getPaymentDetails = async (paymentId) => {
    setModalLoading(true);
    const {data, error} = await payment.getPaymentDetailsData(paymentId);
    if (!error) {
      setPaymentDetails(data);
    } 
    setModalLoading(false);
  }

  const createAccount = async (payload) => {
    setModalLoading(true);
    const { error } = await payment.createAdminAccount(payload);
    if (!error) {
      getAdminAccount({page:1});
    } 
    setModalLoading(false);
  };

  const createOperation = async (payload) => {
    setModalLoading(true);
    const { error } = await payment.createAdminOperation(payload);
    if (!error) {
      getAdminAccount({page:1});
    }
    setModalLoading(false);
    return {success:!error};
  };
  
  const forceCustomerFunding = async (payload) => {
    setIsLoading(true);
    const { error } = await payment.forceFailedCustomerFunding(payload);
    if(!error){
      await getAdminAccount();
    }
    setIsLoading(false);
    return {success:!error};
  };

  return {
    isLoading,
    balance,
    getBalances,
    postVoucherPayment,
    payments,
    getPayments,
    selectedPayment,
    setSelectedPayment,
    patchVoucherPayment,
    pagination,
    getPaymentByStore,
    invoiceList,
    getAdminAccount,
    accountList,
    getPendingPayment,
    pendingPaymentData,
    createPendingPayment,
    paymentDetails,
    setPaymentDetails,
    getPaymentDetails,
    modalLoading,
    getAdminCommissions,
    commisions,
    createAccount,
    createOperation,
    forceCustomerFunding,
  };
};

export { usePayments };
