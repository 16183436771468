import React from "react";
import { Layout } from "antd";
import { Link } from "react-router-dom";
import {
  switchLanguage,
  toggleCollapsedSideNav,
} from "../../appRedux/actions/Setting";
import Auxiliary from "util/Auxiliary";
import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  TAB_SIZE,
} from "../../constants/ThemeSetting";
import { connect } from "react-redux";
import { useAuth } from "../../context/auth-context";
import { ReactComponent as Logo } from "../../assets/images/drinker.svg";
import { numberFormatter } from "../../views/utils/formatters";
import { ReactComponent as DrinkerLogo } from "../../assets/images/drinker_small_logo.svg"

const { Header } = Layout;
const Topbar = (props) => {
  const { width, navCollapsed, navStyle } = props;
  const { storeBalance, isAdmin } = useAuth();

  return (
    <Auxiliary>
      <Header>
        {navStyle === NAV_STYLE_DRAWER ||
        ((navStyle === NAV_STYLE_FIXED ||
          navStyle === NAV_STYLE_MINI_SIDEBAR) &&
          width < TAB_SIZE) ? (
          <div className="gx-linebar gx-mr-3">
            <i
              className="gx-icon-btn icon icon-menu"
              onClick={() => {
                props.toggleCollapsedSideNav(!navCollapsed);
              }}
            />
          </div>
        ) : null}
        <Link to="/" className="gx-d-block gx-d-lg-none gx-pointer">
          <Logo width={80} />
        </Link>
        <ul className="gx-header-notifications gx-ml-auto">
          {!isAdmin && (
            <li>
              Saldo a cobrar: 
              <DrinkerLogo style={{
                marginLeft:8, 
                marginRight:4,
                marginBottom: -2
              }}/>
              <span style={{fontWeight:'600'}}>
                {numberFormatter(storeBalance) || "0,00"}
              </span>
            </li>
          )}
          <li className="gx-msg">
            {/* <Popover
              overlayClassName="gx-popover-horizantal"
              placement="bottomRight"
              content={
                <AppNotification
                  countNotifications={countNotifications}
                  notifications={notifications}
                  onChange={onChange}
                />
              }
              trigger="click"
              visible={isOpenPopUp}
              onVisibleChange={() => onChange()}
            >
              <Badge count={countNotifications} overflowCount={9} size="small">
                <span className="gx-pointer gx-d-block">
                  <i
                    className="icon icon-notification gx-fs-xxl"
                    style={{ position: "relative", top: 5 }}
                  />
                </span>
              </Badge>
            </Popover> */}
          </li>
        </ul>
      </Header>
    </Auxiliary>
  );
};

const mapStateToProps = ({ settings }) => {
  const { locale, navStyle, navCollapsed, width } = settings;
  return { locale, navStyle, navCollapsed, width };
};

export default connect(mapStateToProps, {
  toggleCollapsedSideNav,
  switchLanguage,
})(Topbar);
