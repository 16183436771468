import React, { useEffect, useState } from "react";
import { 
  Card, 
  Table, 
  Checkbox, 
  Popconfirm, 
  Pagination, 
  Tooltip, 
  Input, 
  Row, 
  Col, 
  DatePicker 
} from "antd";
import "moment/locale/es";
import { dateFormat } from "../utils/formatters";
import { useHistory } from "react-router";
import { EyeOutlined, FileSearchOutlined } from "@ant-design/icons";
import { useStores } from "../../context/stores/useStores";

const Stores = () => {
  const history = useHistory();
  const { 
    getStores, 
    isLoading, 
    storeList, 
    verifyStore, 
    pagination 
  } = useStores();
  const [currentPage, setCurrentPage] = useState(1);
  const [tableFilters, setTableFilters] = useState({});
  const [enterPressed, setEnterPressed] = useState(false);
  const [tablaData, setTableData] = useState([]);

  const { RangePicker } = DatePicker;

  const columns = [
    {
      title: "F. de registro",
      width: 120,
      dataIndex: "created_at",
      key: "created_at",
      defaultSortOrder: "descend",
      render: (text, _) => {
        return <span>{dateFormat(text)}</span>;
      },
    },
    {
      title: "Nombre del bar",
      dataIndex: "name",
      align: "center",
      key: "name",
    },
    {
      title: "Nombre de contacto",
      dataIndex: "contact_name",
      align: "center",
      key: "contact_name",
    },
    {
      title: "Cargo",
      dataIndex: "contact_job",
      align: "center",
      key: "contact_job",
    },
    {
      title: "Teléfono",
      dataIndex: "phone",
      align: "center",
      key: "phone",
    },
    {
      title: "Correo",
      dataIndex: "user_email",
      align: "center",
      key: "user_email",
    },
    {
      title: "C.I/Rif",
      dataIndex: "doc_number",
      align: "center",
      key: "doc_number",
    },
    {
      title: "Aprobar",
      dataIndex: "verified",
      align: "center",
      key: "verified",
      render: (_, record) => (
        <Popconfirm
          title={`Estas seguro que quieres ${
            record.checked ? "revocarle acceso a este bar?" : "aprobar este bar"
          } `}
          okText="Yes"
          onConfirm={() =>
            verifyStore(record.id, record.verified ? false : true)
          }
          cancelText="No"
          placement="left"
        >
          <Checkbox checked={record.verified} />
        </Popconfirm>
      ),
    },
    {
      title: "Acciones",
      key: "action",
      width: 150,
      align: "center",
      style: { justifyContent: "center" },
      render: (_, record) => (
        <>
          <Tooltip title="Pagos">
            <FileSearchOutlined
              className="mr-2"
              style={{ fontSize: 20, cursor: "pointer", marginRight: "10px" }}
              onClick={() => {
                history.push( `/stores/payments/${record.id}`, { id: record.id });
              }}
            />
          </Tooltip> 
          <Tooltip title="Ver Perfil">
            <EyeOutlined
              style={{ fontSize: 20, cursor: "pointer" }}
              onClick={() => {
                history.push(`/stores/${record.id}`, { store: record });
              }}
            />
          </Tooltip> 
        </>
      ),
    },
  ];

  const onPagination = (page) => {
    setCurrentPage(page);
  };

  const onChangeSearch = (name) => {
    setCurrentPage(1);
    setTableFilters((prevFilter) => ({ ...prevFilter, name: name }));
  };

  const onChangeDate = (value) => {
    setCurrentPage(1);
    setTableFilters((prevFilter) => ({ ...prevFilter, rangeDate: value }));
  };

  const handleEnterPress = (search) => {
    onChangeSearch(search);
    setEnterPressed(true);
  };

  const handleBlur = (search) => {
    if (!enterPressed) {
      onChangeSearch(search);
    }
    setEnterPressed(false);
  };

  useEffect(() => {
    if(tableFilters && Object.keys(tableFilters).length > 0){
      const transformedFilters = {};
      Object.entries(tableFilters).forEach(([key, value]) => {
        if (Array.isArray(value) && value.length > 0) {
          transformedFilters[key] = value;
        } else {
          transformedFilters[key] = value;
        }
      });
      getStores(currentPage, transformedFilters);
    } else {
      getStores(currentPage)
    }
  }, [currentPage, tableFilters]);

  useEffect(()=>{
    const mappedData = storeList.map((elem)=>{
      const storeData = {
        id: elem.id,
        created_at: elem.created_at,
        name: elem.name,
        contact_name: elem.contact_name,
        contact_job: elem.contact_job,
        phone: elem.phone,
        user_email: elem.user_email,
        doc_number: elem.doc_number,
        verified:  elem.verified,
        user: elem.user,
      }
      return storeData
    })
    setTableData(mappedData);
  },[storeList])

  // useEffect(() => {
  //   handleOnChangeFilters(fromDate, toDate);
  // }, [fromDate, toDate]);

  // useEffect(() => {
  //   if (dataFiltered) {
  //     handleOnChangeFilters(fromDate, toDate, requestType);
  //   }
  // }, [contactRequestData]);

  // const handleOnChangeFilters = (from, to, requestType) => {
  //   if (!contactRequestData.length) {
  //     return;
  //   }
  //   const filterByDate = contactRequestData.filter((dataElem) => {
  //     return moment(dataElem.date).isBetween(from, to, undefined, "[]");
  //   });
  //   const filterByType = filterByDate.filter(
  //     (dataElem) => dataElem.type === requestType
  //   );
  //   if (!from && !to) {
  //     setFromDate(dayFromDefault);
  //     setToDate(dayToDefault);
  //   } else setDataFiltered(!requestType ? filterByDate : filterByType);
  // };

  // useEffect(() => {
  //   let orders = ordersData;
  //   if (orderNumber) {
  //     orders = orders.filter((val) => val.bar_name.startsWith(orderNumber));
  //   } else {
  //     orders = ordersData;
  //   }
  //   if (orderStatus) {
  //     orders = orders.filter((val) => val.status === orderStatus);
  //   } else {
  //     if (!orderNumber) {
  //       orders = ordersData;
  //     }
  //   }
  //   setOrders(orders);
  // }, [orderNumber, orderStatus]);

  return (
    <Card>
      <Row className="gx-justify-content-end gx-mx-0">
        <Col className="gx-mb-3">
          <Input
            placeholder="Nombre del bar"
            style={{ width: 250 }}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                handleEnterPress(event.target.value);
              }
            }}
            onBlur={(event) => handleBlur(event.target.value)}
          />
        </Col>
        <Col className="gx-mb-3 gx-ml-2">
          <RangePicker 
            placeholder={["Fecha inicio", "Fecha final"]}
            style={{width: 250}}  
            onChange={onChangeDate} 
          />
        </Col>
      </Row>
      <Table
        className="gx-table-responsive"
        loading={isLoading}
        columns={columns}
        dataSource={tablaData}
        scroll={{ x: window.innerWidth < 1200 ? 1200 : false }}
        pagination={false}
      />
      <Pagination
        current={pagination.current}
        pageSize={pagination.perPage}
        total={pagination.total}
        onChange={(page) => onPagination(page)}
        style={{textAlign: "end", marginTop: "10px"}}
        showSizeChanger={false}
      />
    </Card>
  );
};

export default Stores;
