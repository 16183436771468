import { notification } from "antd";
import axios from "axios";
import { isArray } from "lodash";
import {
  LOCATIONS_API_URL,
  LOGIN_API_URL,
  ME_API_URL,
  SIGNUP_API_URL,
} from "./constants";

const noAuthEndpoints = [SIGNUP_API_URL, LOCATIONS_API_URL, LOGIN_API_URL];

const doNotShowErrorEndpoint = [ME_API_URL, LOGIN_API_URL];

const errorMessages = {
  [LOGIN_API_URL]: "Contraseña o usuario inválido",
  is_preferential: "Un bar solo puede tener una cuenta preferencial.",
  phone: "Verifica tu número de teléfono."
};

const instance = axios.create({
  baseURL: "https://io.drink-er.com/drinker",
});

instance.interceptors.request.use(async (config) => {
  if (!noAuthEndpoints.includes(config.url || "")) {
    const jwtToken = sessionStorage.getItem("@token");
    if (config.headers) {
      config.headers.Authorization = `Token ${jwtToken}`;
    }
  }
  return config;
});

const catchError = (err, url, onError = () => {}) => {
  let statusCode = 500;
  let message = err.message;
  if (err.response) {
    statusCode = err.response.status;
    if (!!err.response.data) {
      let description = "";
      const storeError = err.response.data.store;
      Object.entries(err.response.data).forEach(([key, value]) => {
        if (isArray(value)) {
          description += errorMessages[key] || `${key}: ${value[0]}\n`;
        } else if (typeof value === "string") {
          description += errorMessages[key] || `${key}: ${value}\n`;
        }
      });
      if (storeError && storeError.phone) {
        onError();
        return;
      }
      message = description;
    }
  }
  if (!doNotShowErrorEndpoint.includes(url)) {
    notification.open({
      type: "error",
      message: statusCode,
      description: errorMessages[url] || message || err.message,
    });
  }
  return {
    error: statusCode,
    data: message,
  };
};

const respAdapter = (resp, successMessage) => {
  if (successMessage) {
    notification.open({
      type: "success",
      message: successMessage,
    });
  }
  return {
    error: null,
    data: resp.data,
  };
};

const get = ({ url, config = {} }) =>
  instance
    .get(url, config)
    .then(respAdapter)
    .catch((err) => catchError(err, url));

const post = ({ url, data = {}, successMessage, config, onError }) =>
  instance
    .post(url, data, config)
    .then((resp) => respAdapter(resp, successMessage))
    .catch((err) => catchError(err, url, onError));

const patch = ({ url, data = {}, successMessage, config }) =>
  instance
    .patch(url, data, config)
    .then((resp) => respAdapter(resp, successMessage))
    .catch((err) => catchError(err, url));

const put = ({ url, data = {}, successMessage, config }) =>
  axios
    .put(url, data, config)
    .then((resp) => respAdapter(resp, successMessage))
    .catch((err) => catchError(err, url));

const deleted = ({ url, successMessage }) =>
  instance
    .delete(url)
    .then((resp) => respAdapter(resp, successMessage))
    .catch((err) => catchError(err, url));

export { get, post, put, deleted, patch };
