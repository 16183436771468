import React, { createContext, useState } from "react";

const StoreContext = createContext({});

function StoreProvider({ children }) {
  const [isLoading, setIsLoading] = useState(false);

  const [storeList, setStoreList] = useState([]);
  const [movementList, setMovementList] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    perPage: 15,
    total: 0,
  });

  return (
    <StoreContext.Provider
      value={{
        isLoading,
        setIsLoading,
        storeList,
        setStoreList,
        pagination, 
        setPagination,
        movementList, 
        setMovementList,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
}

export { StoreProvider, StoreContext };
