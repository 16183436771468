import React, { useEffect, useState } from "react";
import { 
  Card, 
  Table, 
  Tooltip, 
  Pagination, 
  Col, 
  Input,
  DatePicker 
} from "antd";
import "moment/locale/es";
import { DollarOutlined } from "@ant-design/icons";
import UploadVoucher from "./UploadVoucher";
import { usePayments } from "../../context/payment/usePayments";
import { dateFormat, numberFormatter } from "../utils/formatters";
import { ReactComponent as DrinkerLogo } from "../../assets/images/drinker_small_logo.svg";

const Balances = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [store, setStore] = useState("");
  const [tablaData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [tableFilters, setTableFilters] = useState({});
  const [enterPressed, setEnterPressed] = useState(false);

  const { balance, getBalances, pagination, isLoading } = usePayments();
  const { RangePicker } = DatePicker;

  const columns = [
    {
      title: "F. último pago",
      width: 130,
      dataIndex: "last_payment_date",
      key: "last_payment_date",
      defaultSortOrder: "descend",
      render: (text, _) => {
        return <span>{text && dateFormat(text)}</span>;
      },
    },
    {
      title: "Propietario",
      align: "center",
      key: "owner",
      dataIndex: "owner",
    },
    {
      title: "Nombre del bar",
      align: "center",
      key: "bar_name",
      dataIndex: "bar_name",
    },
    {
      title: "Teléfono",
      dataIndex: "phone",
      align: "center",
      key: "phone",
    },
    {
      title: "Banco",
      align: "center",
      key: "bank",
      dataIndex: "bank",
    },
    {
      title: "Nro. Cuenta",
      align: "center",
      key: "account_number",
      dataIndex: "account_number",
    },
    {
      title: "C.I/Rif",
      align: "center",
      key: "doc_number",
      dataIndex: "doc_number",
    },
    {
      title: "Saldo en Bs.",
      align: "center",
      key: "balance_bs",
      dataIndex: "balance_bs",
      render: (text) => {
        return <span>{numberFormatter(text)}</span>;
      },
    },
    {
      title:(
        <span>
          Saldo en
          <DrinkerLogo height={13} style={{marginLeft:4, marginBottom:-2}}/>
        </span>
      ),
      align: "center",
      key: "balance",
      dataIndex: "balance",
      render: (text) => {
        return <span>{numberFormatter(text)}</span>;
      },
    },
    {
      title: "Tasa de cambio",
      dataIndex: "exchange_rate",
      align: "center",
      key: "exchange_rate",
      render: (text) => {
        return <span>{numberFormatter(text)}</span>;
      },
    },
    {
      title: "Acciones",
      key: "action",
      width: 100,
      align: "center",
      render: (_, record) => (
        <>
          <Tooltip title="Pagar">
            <DollarOutlined
              style={{ fontSize: 20, cursor: "pointer" }}
              onClick={() => {
                if (record.balance > 0) {
                  setIsVisible(true);
                  setStore(record.id);
                }
              }}
            />
          </Tooltip>
        </>
      ),
    },
  ];

  const onPagination = (page) => {
    setCurrentPage(page);
  };

  const onChangeSearch = (name) => {
    setCurrentPage(1);
    setTableFilters((prevFilter) => ({ ...prevFilter, name: name }));
  };

  const onChangeDate = (value) => {
    setCurrentPage(1);
    setTableFilters((prevFilter) => ({ ...prevFilter, rangeDate: value }));
  };

  const handleEnterPress = (search) => {
    onChangeSearch(search);
    setEnterPressed(true);
  };

  const handleBlur = (search) => {
    if (!enterPressed) {
      onChangeSearch(search);
    }
    setEnterPressed(false);
  };

  const getTableData = () => {
    if (currentPage === 1){
      getBalances(currentPage)
    } else {
      setCurrentPage(1);
    }
  };

  useEffect(() => {
    if(tableFilters && Object.keys(tableFilters).length > 0){
      const transformedFilters = {};
      Object.entries(tableFilters).forEach(([key, value]) => {
        if (Array.isArray(value) && value.length > 0) {
          transformedFilters[key] = value;
        } else {
          transformedFilters[key] = value;
        }
      });
      getBalances(currentPage, transformedFilters);
    } else {
      getBalances(currentPage)
    }
  }, [currentPage, tableFilters]);

  useEffect(()=>{
    if(balance.balance){
      const mappedData = balance.balance.map((elem)=>{
        const movementData = {
          id: elem.id,
          last_payment_date: elem.last_payment_date,
          owner: elem.contact_name,
          bar_name: elem.name,
          phone: elem.phone,
          bank: elem.preferential_account ? elem.preferential_account.bank_name : "",
          account_number: elem.preferential_account ? elem.preferential_account.number : "",
          doc_number: elem.preferential_account ? elem.preferential_account.doc_number : "",
          balance: elem.balance,
          balance_bs: elem.balance_bolivares,
          exchange_rate: balance.exchange_rate,
          products: elem.products,
        }
        return movementData
      })
      setTableData(mappedData);
    }
  },[balance])

  return (
    <Card>
      <div className=" gx-align-items-center gx-d-flex gx-justify-content-between gx-mx-0 gx-flex-sm-wrap">
        <div className="gx-d-flex gx-mx-0 gx-flex-sm-wrap" >
            { balance.total && (
              <div className="custom-card gx-mb-3 gx-mr-4">
                <div className="custom-card-title">
                  {numberFormatter(balance.total * balance.exchange_rate) || 0} Bs
                </div>
                <div className="custom-card-title">
                  {numberFormatter(balance.total) || 0} <DrinkerLogo height={13}/>
                </div>
                <div>Saldo a pagar</div>
              </div>
            )}
        </div>
        <div className="gx-d-flex gx-justify-content-end gx-mx-0">
            <Col className="gx-mb-3 gx-mr-2">
              <Input
                placeholder="Nombre de bar"
                style={{ width: 250 }}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    handleEnterPress(event.target.value);
                  }
                }}
                onBlur={(event) => handleBlur(event.target.value)}
              />
            </Col>
            <Col className="gx-mb-3">
              <RangePicker 
                placeholder={["Fecha inicio", "Fecha final"]}
                style={{width: 250}}  
                onChange={onChangeDate} 
              />
            </Col>
        </div>
      </div>
      <Table
        className="gx-table-responsive"
        columns={columns || []}
        dataSource={tablaData || []}
        scroll={{ x: true }}
        loading={isLoading}
        pagination={false}
      />
       <Pagination
        current={currentPage}
        pageSize={pagination.perPage}
        total={pagination.total}
        onChange={(page) => onPagination(page)}
        style={{textAlign: "end", marginTop: "10px"}}
        showSizeChanger={false}
      />
      <UploadVoucher
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        store={store}
        action="create"
        withPaymentTable={true}
        getTableData={getTableData}
      />
    </Card>
  );
};

export default Balances;
