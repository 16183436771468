import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  Modal,
  Row,
  Col,
  InputNumber,
} from "antd";
import UploadFile from "../../components/UploadFile/UploadFile";
import { useProducts } from "../../context/products/useProducts";
import { ReactComponent as DrinkerLogo } from "../../assets/images/drinker_small_logo.svg";

const FormItem = Form.Item;
const formItemLayout = {
  labelCol: {
    // xs: { span: 24 },
    // sm: { span: 24 },
    md: { span: 9 },
    // lg: { span: 9 },
  },
  wrapperCol: {
    // xs: { span: 24 },
    // sm: { span: 24 },
    md: { span: 24 },
    // lg: { span: 15 },
  },
};
const ModalPromotion = ({ action = "create", setIsVisible, isVisible }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const [productImage, setProductImage] = useState(null);
  const {
    createOrUpdatePromotion,
    selectedProduct,
    setSelectedProduct,
  } = useProducts();

  const { title, price, description, photo } = selectedProduct;
  const [chartCounter, setChartCounter] = useState({title:0, description:0});

  useEffect(() => {
    form.resetFields();
    setProductImage(null);
    setProductImage(null);
  }, [isVisible]);

  useEffect(() => {
    if (action === "edit") {
      form.setFieldsValue({
        title,
        price,
        description,
        image: photo,
      });
      if(title){
        setChartCounter({title:title.length, description:description.length})
      }
    }
  }, [selectedProduct]);

  const titles = {
    edit: "Editar",
    create: "Nueva",
  };

  const onFinishCreate = (values) => {
    setIsLoading(true);
    createOrUpdatePromotion({
      payload: {
        ...values,
        productImage,
      },
    });
    setIsLoading(false);
    setIsVisible(false);
    setProductImage(null);
  };

  const onFinishEdit = (values) => {
    setIsLoading(true);
    createOrUpdatePromotion({
      payload: {
        ...values,
        productImage,
      },
      update: true,
      productId: selectedProduct.id,
    });
    setIsLoading(false);
    setIsVisible(false);
    setProductImage(null);
  };

  const handleOnChange = (e) => {
    const {name, value} = e.target;
    setChartCounter((prev)=>({...prev, [name]: value.length }))
    form.setFieldsValue({ [name]: value })
  }

  return (
    <Modal
      visible={isVisible}
      footer={null}
      onCancel={() => {
        setIsVisible(false);
        setSelectedProduct({});
        setProductImage(null);
        setChartCounter({title:0, description:0})
      }}
      centered
      title={`${titles[action]}  promoción`}
    >
      <Form
        form={form}
        name="advanced_search"
        {...formItemLayout}
        className="ant-advanced-search-form"
        onFinish={(values) =>
          action === "create" ? onFinishCreate(values) : onFinishEdit(values)
        }
      >
        <FormItem
          name="title"
          label={`Nombre`}
          rules={[
            {
              required: true,
              message: "Complete los campos.",
            },
          ]}
        >
          <Input
            autoComplete="off"
            placeholder="Nombre de la promoción"
            onChange={handleOnChange}
            maxLength={20}
            name="title"
          />
        </FormItem>
        <div className="counter-text">
          {`${chartCounter.title}/${20}`}
        </div> 
        <FormItem
          name="price"
          label={(
            <span>
              Precio en
              <DrinkerLogo height={13} style={{marginLeft:4, marginBottom:-2}}/>
            </span>
          )}
          rules={[
            {
              required: true,
              message: "Complete los campos.",
            },
            {
              message: "El precio de la promoción es requerido",
              validator: (_, value) => {
                if (value > 0 || value === undefined) {
                  return Promise.resolve();
                } else {
                  return Promise.reject(
                    "El precio de la promoción es requerido"
                  );
                }
              },
            },
          ]}
        >
          <InputNumber
            autoComplete="none"
            placeholder="Precio"
          />
        </FormItem>

        <FormItem
          name="description"
          label={`Descripción`}
          rules={[
            {
              required: true,
              message: "Complete los campos.",
            },
          ]}
        >
          <Input.TextArea
            autoComplete="off"
            rows={6}
            placeholder="Descripción de la promoción"
            maxLength={200}
            onChange={handleOnChange}
            name="description"
          />
        </FormItem>
        <div className="counter-text">
          {`${chartCounter.description}/${200}`}
        </div>
        <FormItem name="image" label={"Imagen de la promoción"}>
          <UploadFile image={photo} onChange={setProductImage} />
        </FormItem>
        <div className="footer-wrapper-modal">
          <Row className="footer-row-modal gx-mb-3"></Row>
        </div>
        <div className="footer-wrapper-modal">
          <Row
            className="footer-row-modal"
            style={{ justifyContent: "center", width: "100%", margin: 0 }}
          >
            <Col
              lg={11}
              md={11}
              sm={12}
              style={{ display: "flex", justifyContent: "right" }}
            >
              <Button
                type="secondary"
                onClick={() => {
                  form.resetFields();
                  setIsVisible(false);
                }}
              >
                Cancelar
              </Button>
            </Col>
            <Col lg={11} md={11} sm={12}>
              <Button loading={isLoading} htmlType="submit">
                Guardar
              </Button>
            </Col>
          </Row>
        </div>
      </Form>
    </Modal>
  );
};

export default ModalPromotion;
