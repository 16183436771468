
import React, { useState } from "react";
import { Form } from "antd";
import { Button, Input, Typography } from "antd";
import IntlMessages from "util/IntlMessages";
import { useAuth } from "../../context/auth-context";

const { Text } = Typography;
const FormItem = Form.Item;
const DeleteAccount = () => {
  const [error, setError] = useState("");

  const { deleteAccount, isLoading } = useAuth();

  const [form] = Form.useForm();
  const onFinish = async (values) => {
    const error = await deleteAccount({
      username: values.email.trim(),
      password: values.password.trim(),
    });
    if (error) {
      setError("*Usuario o contraseña inválida");
    }else{

    }
  };
  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo-content-bg gx-app-logo-content-bg-login"></div>
            <div className="gx-app-logo-wid">
              <h1>
                <IntlMessages id="app.userAuth.deleteAccount" />
              </h1>
            </div>
          </div>
          <div className="gx-app-login-content">
            <Form
              initialValues={{ remember: true }}
              form={form}
              onFinish={onFinish}
              className="gx-signin-form gx-form-row0"
            >
              <FormItem
                name="email"
                {...(error ? { validateStatus: "error" } : {})}
                rules={[
                  {
                    type: "email",
                    message: "El correo electrónico no es valido.",
                  },
                  {
                    required: true,
                    message: "Introduzca su correo electrónico.",
                  },
                ]}
              >
                <Input
                  placeholder="Correo electrónico"
                  onChange={() => setError("")}
                />
              </FormItem>
              <FormItem
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Introduzca su contraseña.",
                  },
                ]}
                {...(error ? { validateStatus: "error" } : {})}
              >
                <Input.Password
                  type="password"
                  placeholder="Contraseña"
                  onChange={() => setError("")}
                />
              </FormItem>
              {error && (
                <Text
                  type="danger"
                  style={{ marginBottom: 5, display: "block" }}
                >
                  {error}
                </Text>
              )}

              <FormItem>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    marginTop: "5px",
                  }}
                >
                  <Button
                    type="primary"
                    className="gx-mb-0"
                    htmlType="submit"
                    loading={isLoading}
                  >
                    <IntlMessages id="app.userAuth.deleteAccountButton" />
                  </Button>
                </div>
              </FormItem>
          <ol style={{display: "flex", flexDirection: "column", gap: "0.5rem"}}>
            <li>Ingresa tu nombre de usuario y contraseña de la app de Drinker.</li>
            <li>Al hacer click en "Eliminar", todos tus datos serán eliminados del sistema.</li>
            <li>Para cualquier consulta comunícate con soporte drinker.network@gmail.com</li>
          </ol>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteAccount;
