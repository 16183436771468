import React, { useEffect, useState } from "react";
import { Divider, Tooltip } from "antd";
import SimpleTable from "../../components/table/Basic/SimpleTable";
import ModalOperator from "./ModalProducts";
import DeleteModal from "../../components/Modal/DeleteModal";
import moment from "moment";
import { dateFormat } from "../../util/formatters";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useProducts } from "../../context/products/useProducts";
import { numberFormatter } from "../utils/formatters";
import { ReactComponent as DrinkerLogo } from "../../assets/images/drinker_small_logo.svg";

const Products = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [action, setAction] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const {
    getProducts,
    isLoading,
    productList,
    setSelectedProduct,
    deleteProduct,
    pagination
  } = useProducts();

  const handleOperatorDelete = async (id) => {
    deleteProduct({ productId: id });
  };

  const onPagination = (page) => {
    setCurrentPage(page);
  };

  const columns = [
    {
      title: "Fecha de creación",
      dataIndex: "date",
      key: "date",
      defaultSortOrder: "descend",
      align: "center",
      sorter: (a, b) =>
        new moment(a.date).format("YYYYMMDDHHmmss") -
        new moment(b.date).format("YYYYMMDDHHmmss"),
      sortDirections: ["descend", "ascend"],
      render: (_,{product_info} ) => {
        return <span>{dateFormat(product_info.created_at)}</span>;
      },
    },
    {
      title: "Nombre",
      key: "name",
      dataIndex: "name",
      align: "center",
      render: (_, record) => {
        return <span>{record.product_info.name}</span>;
      },
    },
    {
      title:(
        <span>
          Precio en
          <DrinkerLogo height={13} style={{marginLeft:4, marginBottom:-2}}/>
        </span>
      ),
      key: "names",
      align: "center",
      render: (_, record) => (
        <span>
          {numberFormatter(record.price)}
        </span>
      ),
    },
    {
      title: "Acciones",
      key: "action",
      width: 150,
      align: "center",
      render: (_, record) => (
        <div
          style={{
            display: "flex",
            gap: 2,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Tooltip title="Editar">
            <EditOutlined
              style={{ fontSize: 20, cursor: "pointer" }}
              onClick={() => {
                setAction("edit");
                setIsVisible(true);
                setSelectedProduct(record);
              }}
            />
          </Tooltip>
          <Divider type="vertical" />
          <Tooltip title="Eliminar">
            <DeleteOutlined
              style={{ fontSize: 20, cursor: "pointer" }}
              onClick={() =>
                DeleteModal(record, "este producto", () =>
                  handleOperatorDelete(record.id)
                )
              }
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  useEffect(() => {
    getProducts({page:currentPage})
  }, [currentPage]);

  return (
    <>
      <SimpleTable
        columns={columns}
        loading={isLoading}
        data={productList}
        buttonTitle="Nuevo producto"
        onClickModal={() => {
          setIsVisible(true);
          setAction("create");
        }}
        isRolFilter={true}
        paginationData={pagination}
        onPagination={(pag) => onPagination(pag)}
      />

      <ModalOperator
        action={action}
        isVisible={isVisible}
        setIsVisible={(data) => setIsVisible(data)}
        // allUsers={allUsers}
        // setAllUsers={(data) => setAllUsers(data)}
      />
    </>
  );
};

export default Products;
