export const SIGNUP_API_URL = "/users/";
export const LOGIN_API_URL = "/auth/login/";
export const LOCATIONS_API_URL = "/locations/";
export const ME_API_URL = "/users/me/";
export const LOGOUT_API_URL = "/auth/logout/";
export const BALANCE_API_URL = "/stores/my-balance/";
export const BALANCE_ADMIN_URL = "/admin/stores/balance/";

export const STORES = "/stores/";
export const ADMIN_STORES = "/admin/stores/";

//users
export const USERS_API_URL = "/users/";

// Messages
export const SIGNUP_SUCCESS_MESSAGE = "El registro ha finalizado con éxito";

//Schedules
export const STORE_SCHEDULE_API_URL = "/schedules/";

//Products
export const PRODUCTS_API_URL = "/stores-products/";
export const PRODUCTS_ADMIN_API_URL = "/admin/products/";

//Promotions
export const PROMOTIONS_API_URL = "/stores-promotions/";

//Purchases
export const PURCHASES_API_URL = "/purchases/";

//Admins
export const ADMIN_USERS_API_URL = "/admins/";

// Payment
export const PAYMENT_API_URL = '/stores-payments/';
export const STORES_ACCOUNTS = '/stores-accounts/';
export const STORES_ADMIN_ACCOUNTS = '/admin/stores-fund-accounts/';
export const PAYMENT_ADMIN_API = '/admin/store-payments/';
export const PAYMENT_ADMIN_ACCOUNT = '/admin/funds/accounts/';
export const PAYMENT_ADMIN_OPERATION = '/admin/funds/operations/';
export const COMMISSIONS_ADMIN = '/admin/commissions/';
export const FORCE_FUNDING = '/admin/customer-fundings/force/';

//Settings
export const CURRENCIES_API_URL = '/admin/currencies/';

//Movements
export const ADMIN_MOVEMENTS = '/admin/movements/';