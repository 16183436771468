import React, { useState } from "react";
import { Modal, Table } from "antd";
import { numberFormatter } from "../utils/formatters";
import { dateFormat } from "../utils/formatters";

const MovementDetails = ({
  setIsVisible,
  isVisible,
  data,
  setData,
}) => {
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const onExpand = (expanded, record) => {
    if (expanded) {
      setExpandedRowKeys((prevKeys) => [...prevKeys, record.id]);
    } else {
      setExpandedRowKeys((prevKeys) => prevKeys.filter((key) => key !== record.id));
    }
  };

  const columns = [
    {
      title: "Fecha",
      dataIndex: "created_at",
      align: "center",
      key: "created_at",
      render: (text, _) => {
        return <span>{dateFormat(text)}</span>;
      },
    },
    {
      title: "Monto",
      dataIndex: "amount",
      align: "center",
      key: "amount",
      render: (text) => {
        return <span>{numberFormatter(text)}</span>
      }
    },
  ];

  const productColumns = [
    {
      title: "Producto",
      dataIndex: "name",
      align: "center",
      key: "name",
    },
    {
      title: "Cantidad",
      dataIndex: "purchasehasproduct__quantity",
      align: "center",
      key: "purchasehasproduct__quantity",
    },
    {
      title: "Precio Unitario",
      dataIndex: "store_prices__price",
      align: "center",
      key: "store_prices__price",
    }
  ];
  const promotionColumns = [
    {
      title: "Promoción",
      dataIndex: "name",
      align: "center",
      key: "name",
    },
    {
      title: "Cantidad",
      dataIndex: "quantity",
      align: "center",
      key: "quantity",
    },
  ];

  const productList = (record) => {
    return (
      <>
        {record.products && record.products.length > 0 && (
          <Table
            dataSource={record.products}
            columns={productColumns}
            pagination={false}
            size="small"
          />
        )}
        {record.promotions && record.promotions.length > 0 && (
          <Table
            dataSource={record.promotions}
            columns={promotionColumns}
            pagination={false}
            size="small"
          />
        )}
      </>
    )
  }

  const checkExpandable = (record) => {
    if(record.products){
      return record.products.length > 0
    }
    if(record.promotions){
      return record.promotions.length > 0
    }
    return false
  };

  const DetailProducts = () => {
    if(data.length > 0){
      return (
        <Table
          rowKey="id"
          className="gx-table-responsive custom-table-products"
          columns={columns}
          dataSource={data}
          expandable={{
            expandedRowRender: (record, idx) => productList(record.gift_info, idx),
            rowExpandable: (record) => checkExpandable(record.gift_info),
            expandedRowKeys,
            onExpand,
          }}
          pagination={false}
          size="small"
        />
      )
    };
    return null;
  };

  return (
    <Modal
      visible={isVisible}
      footer={null}
      onCancel={() => {setIsVisible(false); setData([])}}
      centered
      title="Detalles"
    >
        <DetailProducts/>
    </Modal>
  );
};

export default MovementDetails;
