import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import Orders from "../views/Orders";
import Products from "../views/Products";
import Profile from "../views/Profile/Profile";
import Promotions from "../views/Promotions/Promotions";
import Invoices from "../views/Invoices/Invoices";
import { useAuth } from "../context/auth-context";
import Stores from "../views/Stores/Stores";
import Settings from "../views/Settings";
import Payments from "../views/Stores/Payments";
import Users from "../views/Users/Users";
import Balances from "../views/Balances";
import Movements from "../views/Movements/Movements";
import ManageProducts from "../views/Products/ManageProducts";
import { ProfileProvider } from "../context/profile/profile-context";
import { StoreProvider } from "../context/stores/stores-context";
import { ProductsProvider } from "../context/products/products-context";
import { PurchasesProvider } from "../context/purchases/purchases-context";
import { PaymentProvider } from "../context/payment/payments-context";
import { UsersProvider } from "../context/Users/users-context";
import { SettingsProvider } from "../context/settings/settings-context";

const App = () => {
  const user = useAuth();

  const adminRoutes = [
    <Route
      path={`/stores`}
      exact
      component={() => {
        return (
          <StoreProvider>
            <Stores />
          </StoreProvider>
        );
      }}
    />,
    <Route
      path={`/users`}
      exact
      component={() => {
        return (
          <UsersProvider>
            <Users />
          </UsersProvider>
        );
      }}
    />,
    <Route
      path={`/stores/payments/:id`}
      component={() => {
        return (
          <PaymentProvider>
            <Payments />
          </PaymentProvider>
        );
      }}
    />,
    <Route
      path={`/stores/:id`}
      component={() => {
        return (
          <ProfileProvider>
            <Profile />
          </ProfileProvider>
        );
      }}
    />,
    <Route
      path={`/balances`}
      component={() => {
        return (
          <PaymentProvider>
            <Balances />
          </PaymentProvider>
        );
      }}
    />,
    <Route
      path={`/movements`}
      component={() => {
        return (
          <StoreProvider>
            <PaymentProvider>
              <Movements />
            </PaymentProvider>
          </StoreProvider>
        );
      }}
    />,
    <Route
      path={`/manage-products`}
      component={() => {
        return (
          <ProductsProvider>
            <ManageProducts />
          </ProductsProvider>
        );
      }}
    />,
    <Route
      path={`/settings`}
      exact
      component={() => {
        return (
          <SettingsProvider>
            <Settings />
          </SettingsProvider>
        );
      }}
    />,
    <Redirect to={`/stores`} />,
  ];

  const storeRoutes = [
    <Route
      path={`/products`}
      component={() => {
        return (
          <ProductsProvider>
            <Products />
          </ProductsProvider>
        );
      }}
    />,
    <Route
      path={`/promotions`}
      component={() => {
        return (
          <ProductsProvider>
            <Promotions />
          </ProductsProvider>
        );
      }}
    />,
    <Route
      path={`/orders`}
      component={() => {
        return (
          <PurchasesProvider>
            <Orders />
          </PurchasesProvider>
        );
      }}
    />,
    <Route
      path={`/invoices`}
      component={() => {
        return (
          <PaymentProvider>
            <Invoices />;
          </PaymentProvider>
        );
      }}
    />,
    <Route
      path={`/profile`}
      component={() => {
        return (
          <ProfileProvider>
            <Profile />
          </ProfileProvider>
        );
      }}
    />,
    <Redirect to={`/products`} />,
  ];
  return (
    <div className="gx-main-content-wrapper">
      <Switch>{user.isAdmin ? adminRoutes : storeRoutes}</Switch>
    </div>
  );
};
export default App;
