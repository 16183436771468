import React, { useEffect } from "react";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Upload } from "antd";
import { useState } from "react";
const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};
const beforeUpload = (file) => {
  // const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  // if (!isJpgOrPng) {
  //   message.error("Sólo puede cargar archivos JPG/PNG.");
  // }
  // const isLt2M = file.size / 1024 / 1024 < 2;
  // if (!isLt2M) {
  //   message.error("Image must smaller than 2MB!");
  // }
  // return isJpgOrPng && isLt2M;
};
const UploadFile = ({ image, onChange, justShow }) => {
  const [loading] = useState(false);
  const [imageUrl, setImageUrl] = useState();

  useEffect(() => {
    setImageUrl(image);
  }, [image]);

  // const handlePreview = async (file) => {
  //   if (!file.url && !file.preview) {
  //     file.preview = await getBase64(file.originFileObj);
  //   }
  //   setImageUrl(file.url || file.preview);
  // };

  const handleChange = async ({ fileList: newFileList }) => {
    getBase64(newFileList.reverse()[0].originFileObj, (file) => {
      setImageUrl(file);
      onChange(newFileList[0].originFileObj);
    });
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );
  return (
    <Upload
      name="avatar"
      listType="picture-card"
      className="avatar-uploader"
      showUploadList={false}
      customRequest={() => {}}
      beforeUpload={beforeUpload}
      onChange={handleChange}
      disabled={justShow}
    >
      {imageUrl ? (
        <img
          src={imageUrl}
          alt="avatar"
          style={{
            width: "100%",
          }}
        />
      ) : (
        !justShow ? uploadButton : <></> 
      )}
    </Upload>
  );
};
export default UploadFile;
