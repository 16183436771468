import moment from "moment";

export const dateFormat = (date) => {
  return moment(date).format("YYYY-MM-DD");
};

export const numberFormatter = (number) => {
  if(!number){
    return
  };
  const [integer, decimal] = Number(number).toFixed(2).split('.');
  const formattedInteger = integer.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  const formattedNumber = `${formattedInteger},${decimal}`;
  return formattedNumber;
}
