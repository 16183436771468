import React from "react";
import { ReactComponent as Logo } from "../../assets/images/icons/pin.svg";

export default function OnReview() {
  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div
            className="gx-app-logo-content"
            style={{ height: "60vh", width: "100%" }}
          >
            <div
              className="gx-app-logo-wid"
              style={{
                height: "100%",
                width: "100%",
                justifyContent: "center",
                gap: "2rem",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Logo width="180px" height="180px" />

              <h1
                style={{ color: "black", fontWeight: 500, textAlign: "center" }}
              >
                Tu cuenta se encuentra en revisión, este proceso puede tardar
                entre 24 a 48 horas.
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
