import React, { useEffect } from "react";
import { connect } from "react-redux";
import URLSearchParams from "url-search-params";
import { Redirect, Route, Switch } from "react-router-dom";
import { ConfigProvider } from "antd";
import { IntlProvider } from "react-intl";

import AppLocale from "lngProvider";
import MainApp from "./MainApp";
import SignIn from "../../views/Auth/SignIn";
import DeleteAccount from "../../views/Auth/DeleteAccount";

import { setInitUrl } from "appRedux/actions/Auth";
import {
  onLayoutTypeChange,
  onNavStyleChange,
  setThemeType,
} from "appRedux/actions/Setting";

import {
  LAYOUT_TYPE_BOXED,
  LAYOUT_TYPE_FRAMED,
  LAYOUT_TYPE_FULL,
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
} from "../../constants/ThemeSetting";
import WrappedForgotPasswordForm from "../../views/Auth/ForgotPassword";
import { useAuth } from "../../context/auth-context";
import RessetPassword from "../../views/Auth/ResetPassword";
import SignUp from "../../views/Auth/Signup";
import OnReview from "../../views/Auth/OnReview";
import SplashScreen from "../../views/SplashScreen/SplashScreen";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// const firebaseConfig = {
//   apiKey: "AIzaSyDmdhTtb-oJS64VOdiuVge1vu_p4v4hjEE",
//   authDomain: "sylvan-presence-350212.firebaseapp.com",
//   projectId: "sylvan-presence-350212",
//   storageBucket: "sylvan-presence-350212.appspot.com",
//   messagingSenderId: "867143683732",
//   appId: "1:867143683732:web:1bcca4d67ee12db0b2ee81",
// };

// Initialize Firebase
// const app = initializeApp(firebaseConfig);

const App = (props) => {
  const auth = useAuth();
  // const [socketIsActive, setsocketIsActive] = useState(false);
  // const [socket, setSocket] = useState(null);
  // const { setNotifications } = useNotifications();

  // const handleNewNotifications = (data) => {
  //   if (data.type === "allNotifications") {
  //     setNotifications(data.notifications);
  //   } else {
  //     setNotifications((prev) => [...prev, JSON.parse(data.notification)]);
  //   }
  // };

  // useEffect(() => {
  //   if (auth.user && !socketIsActive) {
  //     const socketClient = getSocketClient(auth.user.id);
  //     setsocketIsActive(true);
  //     setSocket(socketClient);
  //     socketClient.onopen = () => {
  //       console.log("Socket client connected");
  //     };
  //     socketClient.onmessage = (message) => {
  //       const data = JSON.parse(message.data);
  //       handleNewNotifications(data);
  //     };
  //   } else if (!auth.user && socketIsActive) {
  //     socket.close();
  //     setsocketIsActive(false);
  //   }
  // }, [auth]);
  const { location, layoutType, navStyle, locale, authUser, initURL } = props;

  const setLayoutType = (layoutType) => {
    if (layoutType === LAYOUT_TYPE_FULL) {
      document.body.classList.remove("boxed-layout");
      document.body.classList.remove("framed-layout");
      document.body.classList.add("full-layout");
    } else if (layoutType === LAYOUT_TYPE_BOXED) {
      document.body.classList.remove("full-layout");
      document.body.classList.remove("framed-layout");
      document.body.classList.add("boxed-layout");
    } else if (layoutType === LAYOUT_TYPE_FRAMED) {
      document.body.classList.remove("boxed-layout");
      document.body.classList.remove("full-layout");
      document.body.classList.add("framed-layout");
    }
  };

  const setNavStyle = (navStyle) => {
    if (
      navStyle === NAV_STYLE_DEFAULT_HORIZONTAL ||
      navStyle === NAV_STYLE_DARK_HORIZONTAL ||
      navStyle === NAV_STYLE_INSIDE_HEADER_HORIZONTAL ||
      navStyle === NAV_STYLE_ABOVE_HEADER ||
      navStyle === NAV_STYLE_BELOW_HEADER
    ) {
      document.body.classList.add("full-scroll");
      document.body.classList.add("horizontal-layout");
    } else {
      document.body.classList.remove("full-scroll");
      document.body.classList.remove("horizontal-layout");
    }
  };

  useEffect(() => {
    if (props.initURL === "") {
      props.setInitUrl(props.history.location.pathname);
    }
    const params = new URLSearchParams(props.location.search);

    if (params.has("theme")) {
      props.setThemeType(params.get("theme"));
    }
    if (params.has("nav-style")) {
      props.onNavStyleChange(params.get("nav-style"));
    }
    if (params.has("layout-type")) {
      props.onLayoutTypeChange(params.get("layout-type"));
    }

    if (location.pathname === "/") {
      if (authUser === null) {
        return <Redirect to={"/signin"} />;
      } else if (initURL === "" || initURL === "/" || initURL === "/signin") {
        return <Redirect to={"/metrics"} />;
      } else {
        return <Redirect to={initURL} />;
      }
    }
  }, []);

  setLayoutType(layoutType);

  setNavStyle(navStyle);

  const currentAppLocale = AppLocale[locale.locale];

  // const getFCMToken = () => {
  //   getToken(messaging, {
  //     vapidKey:
  //       "BJ7kLssZvv6-5bu8f9dU9NBvg9i1e1Vr4iSYYaPirGkNGcFVdVbL5b8nOnuXcNC5-0PI9MDVd7iYfbEUxClXs5o",
  //   })
  //     .then((currentToken) => {
  //       if (currentToken) {
  //         // Send the token to your server and update the UI if necessary
  //         // ...
  //         console.log(currentToken);
  //       } else {
  //         // Show permission request UI
  //         console.log(
  //           "No registration token available. Request permission to generate one."
  //         );
  //         // ...
  //       }
  //     })
  //     .catch((err) => {
  //       console.log("An error occurred while retrieving token. ", err);
  //       // ...
  //     });
  // };

  // const deleteTokenFunc = async () => {
  //   await deleteToken(messaging);
  // };

  // const requestToken = async () => {
  //   // await deleteTokenFunc();
  //   Notification.requestPermission().then((permission) => {
  //     console.log(permission);
  //     getFCMToken();
  //   });
  //   // Notification.requestPermission().then((permission) => {
  //   //   if (permission === 'granted') {
  //   //     console.log('Notification permission granted.');
  //   //   }
  // };

  useEffect(() => {
    // requestToken();
    auth.me();
  }, []);

  // onMessage(messaging, (payload) => {
  //   console.log("Message received infore ground", payload);
  // });

  const renderApp = () => {
    if (auth.gettingCurrentUser) {
      return <SplashScreen />;
    }

    if (!auth.user) {
      return (
        <Switch>
          <Route exact path="/signin" component={SignIn} />
          <Route exact path="/delete-account" component={DeleteAccount} />
          <Route exact path="/review" component={OnReview} />
          <Route exact path="/signUp" component={SignUp} />
          <Route
            path={"/forgot-password"}
            component={WrappedForgotPasswordForm}
          />
          <Route path={"/reset-password"} component={RessetPassword} />
          <Redirect to="/signin" />
        </Switch>
      );
    }

    return <MainApp />;
  };
  return (
    <ConfigProvider
      locale={currentAppLocale.antd}
      getPopupContainer={(node) => {
        if (node) {
          return node.parentNode;
        }
        return document.body;
      }}
    >
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}
      >
        {renderApp()}
      </IntlProvider>
    </ConfigProvider>
  );
};

const mapStateToProps = ({ settings, auth }) => {
  const { locale, navStyle, layoutType } = settings;
  const { authUser, initURL } = auth;
  return { locale, navStyle, layoutType, authUser, initURL };
};
export default connect(mapStateToProps, {
  setInitUrl,
  setThemeType,
  onNavStyleChange,
  onLayoutTypeChange,
})(App);
