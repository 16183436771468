import React, { useEffect, useState } from "react";
import { 
  Card, 
  Table, 
  Pagination, 
  Tooltip, 
  Input, 
  Col, 
  DatePicker,
  Button,
} from "antd";
import "moment/locale/es";
import { dateFormat } from "../utils/formatters";
import { EyeOutlined, DownloadOutlined, SyncOutlined } from "@ant-design/icons";
import { useStores } from "../../context/stores/useStores";
import { usePayments } from "../../context/payment/usePayments";
import { numberFormatter } from "../utils/formatters";
import { downloadFile } from "../utils/downloadFile";
import MovementDetails from "./MovementDetails";
import { filterDropdown } from "../utils/tableFilters";
import ModalOperation from "./ModalOperation";
import { ReactComponent as DrinkerLogo } from "../../assets/images/drinker_small_logo.svg";
import { LoadingOutlined } from "@ant-design/icons";

const Stores = () => {
  const { 
    getMovements,
    isLoading, 
    movementList, 
    pagination 
  } = useStores();
  const {
    getAdminAccount,
    getAdminCommissions,
    getBalances,
    accountList,
    commisions,
    balance,
    forceCustomerFunding,
    isLoading : loadingFunding,
  } = usePayments();
  const [currentPage, setCurrentPage] = useState(1);
  const [tableFilters, setTableFilters] = useState({});
  const [enterPressed, setEnterPressed] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [paymentDetails, setPaymentDetails] = useState([]);
  const [actionOperation, setActionOperation] = useState("transfer");
  const [isOpenOperation, setIsOpenOperation] = useState(false);

  const { RangePicker } = DatePicker;

  const operations = {
    FUNDING:"Recarga en drinker", 
    ADMIN_FUNDING:"Recarga manual",
    ADMIN_BAR_PAYMENT:"Pago a bar", 
    ADMIN_FUNDS_WITHDRAWAL:"Retiro de saldo", 
    FUNDS_EXCHANGE_ORIGIN:"Cambio de saldo (Origen)", 
    FUNDS_EXCHANGE_DESTINATION:"Cambio de saldo (Destino)", 
    GIFT_RECEIVED:"Regalo recibido", 
    GIFT_SENT:"Regalo enviado",
    GIFT_REFUNDED:"Devolución de drinkers",
    GIFT_REJECTED:"Regalo rechazado",
    GIFT_EXPIRED: "Regalo expirado",
    GIFT_CLAIMED:"Regalo consumido",
    BAR_CLAIM_PAYMENT:"Regalo despachado"
  };

  const statusType = {
    SUCCESSFUL: "Exitoso",
    REJECTED: "Rechazado",
  };

  const account_type = {
    PAYPAL: "Paypal",
    STRIPE: "Stripe",
    MERCANTIL_PAGO_MOVIL: "Mercantil",
  }

  const giftType = ["GIFT_RECEIVED", "GIFT_CLAIMED", "GIFT_REJECTED", "GIFT_SENT", "GIFT_REFUNDED", "BAR_CLAIM_PAYMENT", "GIFT_EXPIRED"];

  const getUserName = (record) => {
    const usersRecipient = ["GIFT_RECEIVED", "GIFT_CLAIMED", "GIFT_REJECTED", "GIFT_EXPIRED"];
    if(usersRecipient.includes(record.movement_type)){
      return record.gift_info && record.gift_info.recipient_username;
    }
    if(record.movement_type === "BAR_CLAIM_PAYMENT"){
      return record.bar_claim_info && record.bar_claim_info.store_name;
    } else{
      return record.username;
    }
  };

  const getAccountName = (record) => {
    const destination = ["FUNDS_EXCHANGE_DESTINATION", "ADMIN_FUNDING"];
    const origin =["FUNDS_EXCHANGE_ORIGIN", "ADMIN_FUNDS_WITHDRAWAL"];
    if (giftType.includes(record.movement_type)){
      return record.gift_info.account; 
    }
    if (destination.includes(record.movement_type)){
      return record.operation_info.dest_acc_name
    }
    if (origin.includes(record.movement_type)){
      return record.operation_info.origin_acc_name
    }
    if (record.movement_type === 'ADMIN_BAR_PAYMENT'){
      return accountList.find((acc) => acc.id === record.store_payment.funds_account_origin).name;
    }
    if (record.movement_type === 'FUNDING'){
      return account_type[record.funding.purchased_via]
    }
    return "N/A"
  };

  const getStatus = (record) => {
    if(record.movement_type === "FUNDING"){
      return statusType[record.funding.status] || record.funding.status;
    }
    return "Exitoso";
  };

  const getAmount = (record) => {
    const operations = ["ADMIN_FUNDING", "ADMIN_FUNDS_WITHDRAWAL"];
    const dollarAccounts = ["Stripe", "Paypal"];
    const fundsExchange = ["FUNDS_EXCHANGE_DESTINATION", "FUNDS_EXCHANGE_ORIGIN"];
    if(operations.includes(record.movement_type)){
      if(dollarAccounts.includes(record.operation_info.dest_acc_name) || dollarAccounts.includes(record.operation_info.origin_acc_name)){
        return numberFormatter(record.amount);
      } else {
        return ""
      }
    }
    if (record.movement_type === 'FUNDING'){
      if(record.funding.purchased_via === "MERCANTIL_PAGO_MOVIL"){
        return numberFormatter(record.amount)
      }
    }
    if (record.movement_type === 'FUNDING'){
      if(record.funding.purchased_via === "MERCANTIL_PAGO_MOVIL"){
        return numberFormatter(record.amount)
      }
    }
    if (fundsExchange.includes(record.movement_type)){
      if(dollarAccounts.includes(record.operation_info.dest_acc_name) || dollarAccounts.includes(record.operation_info.origin_acc_name)){
        return `${numberFormatter(record.amount)}` 
      } else {return}
    }
    return  numberFormatter(record.amount);
  };

  const getAmountBs = (record) => {
    const fundsExchange = ["FUNDS_EXCHANGE_DESTINATION", "FUNDS_EXCHANGE_ORIGIN"];
    const operations = ["ADMIN_FUNDING", "ADMIN_FUNDS_WITHDRAWAL"];
    if (giftType.includes(record.movement_type)){
      return "" 
    }
    if (fundsExchange.includes(record.movement_type)){
      if(record.operation_info.dest_acc_name === "Mercantil" || record.operation_info.origin_acc_name === "Mercantil"){
        return `${numberFormatter(record.operation_info.amount_bolivares)}` 
      }
    }
    if (record.movement_type === 'ADMIN_BAR_PAYMENT'){
      const accountName = accountList.find((acc) => acc.id === record.store_payment.funds_account_origin).name;
      if(accountName === "Mercantil"){
        const amountBs = Number(record.store_payment.amount)*Number(record.store_payment.usd_exchange_rate);
        return `${numberFormatter(amountBs)}` 
      } else return
    }
    if(operations.includes(record.movement_type)){
      if(record.operation_info.dest_acc_name === "Mercantil" || record.operation_info.origin_acc_name === "Mercantil"){
        return `${numberFormatter(record.operation_info.amount_bolivares)}`
      } 
    }
    if (record.movement_type === 'FUNDING'){
      if(record.funding.purchased_via === "MERCANTIL_PAGO_MOVIL"){
        return numberFormatter(record.amount_bolivares)
      }
    }
    return ""
  };

  const getRate = (record) => {
    const fundsExchange = ["FUNDS_EXCHANGE_DESTINATION", "FUNDS_EXCHANGE_ORIGIN"];
    const operations = ["ADMIN_FUNDING", "ADMIN_FUNDS_WITHDRAWAL"];
    if (giftType.includes(record.movement_type)){
      return 
    }
    if (fundsExchange.includes(record.movement_type)){
      if(record.operation_info.usd_exchange_rate){
        return numberFormatter(record.operation_info.usd_exchange_rate);
      } else return numberFormatter(record.operation_info.commission_rate);
    }
    if(record.movement_type === "ADMIN_BAR_PAYMENT"){
      const accountName = accountList.find((acc) => acc.id === record.store_payment.funds_account_origin).name;
      if(accountName === "Mercantil"){
        return numberFormatter(record.store_payment.usd_exchange_rate);
      } else return
    }
    if(record.store_payment){
      return numberFormatter(record.store_payment.usd_exchange_rate);
    }
    if (operations.includes(record.movement_type)){
      return
    }
    if(record.movement_type === "FUNDING"){
      if(record.funding.purchased_via === "MERCANTIL_PAGO_MOVIL"){
        return numberFormatter(record.funding.usd_exchange_rate);
      }
    }
  };

  const getAmountColor = (record, number) => {
    if(!number) return "";
    const redColor = ["GIFT_SENT", "GIFT_CLAIMED", "GIFT_REJECTED", "FUNDS_EXCHANGE_ORIGIN", "ADMIN_FUNDS_WITHDRAWAL", "ADMIN_BAR_PAYMENT", "GIFT_EXPIRED"];
    const isRed = redColor.includes(record.movement_type);
    const color = isRed ? "red" : "green";
    const displayNumber = /^[-+]/.test(number) ? number : `${isRed ? '-' : '+'}${number}`;
    if(record.funding && record.funding.status === 'FAILED'){
      return <span style={{ color: "#999999" }}>{displayNumber}</span>;
    };
    return <span style={{ color }}>{displayNumber}</span>;
  };

  const getCommission = (record) => {
    if(record.movement_type === "FUNDS_EXCHANGE_DESTINATION"){
      return
    }
    if(record.movement_type === "BAR_CLAIM_PAYMENT"){
      return numberFormatter(record.commission_amount) || 0;
    }
    return numberFormatter(record.commission_amount);
  };

  const handlerDetails = (data) => {
    setPaymentDetails([data])
    setShowDetails(true);
  };

  const forceFunding = async (record) => {
    const {success} = await forceCustomerFunding({fundingId: record.funding.id});
    if(success){
      const transformedFilters = {};
      Object.entries(tableFilters).forEach(([key, value]) => {
        if (Array.isArray(value) && value.length > 0) {
          if(key === "rangeDate"){
            transformedFilters[key] = value;
          } else {
            transformedFilters[key] = value[0];
          }
        } else {
          transformedFilters[key] = value;
        }
      });
      getMovements(currentPage, transformedFilters);
    };
  };

  const columns = [
    {
      title: "Fecha",
      width: 120,
      dataIndex: "created_at",
      key: "created_at",
      defaultSortOrder: "descend",
      render: (text, _) => {
        return <span>{dateFormat(text)}</span>;
      },
    },
    {
      title: "ID",
      dataIndex: "id",
      align: "center",
      key: "id",
    },
    {
      title: "Usuario",
      dataIndex: "username",
      align: "center",
      key: "username",
      render: (_, record) => {
        return <span>{getUserName(record)}</span>
      }
    },
    {
      title: "Tipo de op.",
      dataIndex: "movement_type",
      align: "center",
      key: "movement_type",
      width: 140,
      render: (text) => {
        return <span>{operations[text]} </span>;
      },
      filterDropdown: (props) => {
        const options = Object.entries(operations).map(([value, text]) => ({
            label: text,
            value,
        }));
        const {setSelectedKeys, selectedKeys, confirm, clearFilters} = props;
        return filterDropdown(options, setSelectedKeys, selectedKeys, confirm, clearFilters);
      }
    },
    {
      title: "Cuenta",
      dataIndex: "account",
      align: "center",
      key: "account",
      render: (_, record) => {
        return <span>{getAccountName(record)}</span>
      },
    },
    {
      title: "Monto en Bs",
      dataIndex: "amount",
      align: "center",
      key: "amount",
      render: (_, record) => {
        return getAmountColor(record, getAmountBs(record));
      },
    },
    {
      title: (
        <span>
          Ganancia en
          <DrinkerLogo height={13} style={{marginLeft:4, marginBottom:-2}}/>
        </span>
      ),
      dataIndex: "commission_amount",
      align: "center",
      key: "commission_amount",
      render: (_, record) => {
        return getCommission(record);
      },
    },
    {
      title: (
        <span>
          Monto en
          <DrinkerLogo height={13} style={{marginLeft:4, marginBottom:-2}}/>
        </span>
      ),
      dataIndex: "amount",
      align: "center",
      key: "amount",
      render: (_, record) => {
        return getAmountColor(record, getAmount(record));
      },
    },
    {
      title: "Tasa",
      dataIndex: "rate",
      align: "center",
      key: "rate",
      render: (_, record) => {
        return <span>{getRate(record)}</span>;
      },
    },
    {
      title: "Estado",
      dataIndex: "status",
      align: "center",
      key: "status",
      /*filterDropdown: (props) => {
        const options = Object.entries(statusOptions).map(([value, text]) => ({
          label: text,
          value,
        }));
        const {setSelectedKeys, selectedKeys, confirm, clearFilters} = props;
        return filterDropdown(options, setSelectedKeys, selectedKeys, confirm, clearFilters);
      }, */
      render: (_, record) => {
        return <span>{getStatus(record)}</span>;
      },
    },
    {
      title: "Acciones",
      key: "action",
      width: 150,
      align: "center",
      style: { justifyContent: "center" },
      render: (_, record) => (
        <>
          { record.movement_type === "FUNDING" && record.funding.status === "FAILED" && (
            <>
              {loadingFunding ? (
                <LoadingOutlined/>
              ):(
                <Tooltip title="Forzar recarga">
                  <div style={{display: "flex", alignItems: "center", gap: "5px", justifyContent: "center"}}>

                  <SyncOutlined
                    style={{ fontSize: 20, cursor: "pointer"}}
                    onClick={() => forceFunding(record)}
                  />
                  <span>{record.funding.reference}</span>
                  </div>
                </Tooltip> 
              )}
            </>
          )}
          { record.movement_type === "ADMIN_BAR_PAYMENT" && (
            <Tooltip title="Comprobante">
              <DownloadOutlined
                className="mr-2"
                style={{ fontSize: 20, cursor: "pointer", marginRight: "10px" }}
                onClick={()=>downloadFile(record.store_payment.receipt, 'Comprobante')}
              />
            </Tooltip>
          )}
          { giftType.includes(record.movement_type) && (
            <Tooltip title="Detalle">
              <EyeOutlined
                style={{ fontSize: 20, cursor: "pointer" }}
                onClick={()=> handlerDetails(record)}
              />
            </Tooltip>
          )}
        </>
      ),
    },
  ];

  const onPagination = (page) => {
    setCurrentPage(page);
  };

  const onChangeSearch = (name) => {
    setCurrentPage(1);
    setTableFilters((prevFilter) => ({ ...prevFilter, username: name }));
  };

  const onChangeDate = (value) => {
    setCurrentPage(1);
    setTableFilters((prevFilter) => ({ ...prevFilter, rangeDate: value }));
  };

  const onChangeFilter = (_, filters) => {
    setCurrentPage(1);
    setTableFilters((prevFilter) => ({ ...prevFilter, ...filters }));
  };

  const handleEnterPress = (search) => {
    onChangeSearch(search);
    setEnterPressed(true);
  };

  const handleBlur = (search) => {
    if (!enterPressed) {
      onChangeSearch(search);
    }
    setEnterPressed(false);
  };

  useEffect(()=>{
    getAdminAccount();
    getAdminCommissions();
    getBalances(1,{});
  },[]);

  useEffect(() => {
    if(tableFilters && Object.keys(tableFilters).length > 0){
      const transformedFilters = {};
      Object.entries(tableFilters).forEach(([key, value]) => {
        if (Array.isArray(value) && value.length > 0) {
          if(key === "rangeDate"){
            transformedFilters[key] = value;
          } else {
            transformedFilters[key] = value[0];
          }
        } else {
          transformedFilters[key] = value;
        }
      });
      getMovements(currentPage, transformedFilters);
    } else {
      getMovements(currentPage)
    }
  }, [currentPage, tableFilters]);

  return (
    <Card>
      <div 
        className=" gx-align-items-center gx-d-flex gx-justify-content-between gx-mx-0"
        style={{flexWrap:'wrap'}}  
      >
        <div 
          className="gx-d-flex gx-mx-0 gx-flex-sm-wrap" 
          style={{flexWrap:'wrap'}}
        >
            { accountList && accountList.map((acc, idx) => (
                <div key={idx} className="custom-card-small gx-mb-3 gx-mr-3">
                  <div className="custom-card-small-title gx-mb-2">
                    {numberFormatter(acc.balance) || 0} {acc.currency === "VES" ? "Bs" : <DrinkerLogo height={13}/>}
                  </div>
                  <div>{acc.name}</div>
                </div>
            ))}
            {  (
              <div className="custom-card-small gx-mb-3 gx-mr-3">
                <div className="custom-card-small-title">
                  {numberFormatter(commisions.commissions_total_bolivares) || 0} Bs
                </div>
                <div className="custom-card-small-title gx-mb-1">
                  {numberFormatter(commisions.commissions_total) || 0} <DrinkerLogo height={13}/>
                </div>
                <div>Ganancia total</div>
              </div>
            )}
            { (
              <div className="custom-card-small gx-mb-3 gx-mr-3">
                <div className="custom-card-small-title">
                  {numberFormatter(balance.total * balance.exchange_rate) || 0} Bs
                </div>
                <div className="custom-card-small-title gx-mb-1">
                  {numberFormatter(balance.total) || 0} <DrinkerLogo height={13}/>
                </div>
                <div>Saldo a pagar</div>
              </div>
            )}
        </div>
        <div 
          className="gx-d-flex gx-justify-content-between gx-mx-0 gx-w-100"
          style={{flexWrap:'wrap'}}
        >
            <div className="gx-d-flex gx-flex-row">
              <Col className="gx-mb-3 gx-mr-2">
                <Input
                  placeholder="Buscador de usuario"
                  style={{ width: 250 }}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      handleEnterPress(event.target.value);
                    }
                  }}
                  onBlur={(event) => handleBlur(event.target.value)}
                />
              </Col>
              <Col className="gx-mb-3 gx-mr-2">
                <RangePicker 
                  placeholder={["Fecha inicio", "Fecha final"]}
                  style={{width: 250}}  
                  onChange={onChangeDate} 
                />
              </Col>
            </div>
            <div className="gx-d-flex gx-flex-row">
              <Col className="gx-mb-3 gx-mr-2">
                <Button 
                  type="primary" 
                  onClick={() => {
                    setActionOperation("recharge");
                    setIsOpenOperation(true);
                  }}
                >
                  Recargas
                </Button>
              </Col>
              <Col className="gx-mb-3 gx-mr-2">
                <Button 
                  type="primary" 
                  onClick={() => {
                    setActionOperation("withdrawal");
                    setIsOpenOperation(true);
                  }}
                >
                  Retiros
                </Button>
              </Col>
              <Col className="gx-mb-3">
                <Button 
                  type="primary" 
                  onClick={() => {
                    setActionOperation("transfer");
                    setIsOpenOperation(true);
                  }}
                >
                  Cambio de saldo
                </Button>
              </Col>
            </div>
        </div>
      </div>
      <Table
        className="gx-table-responsive"
        loading={isLoading}
        columns={columns}
        dataSource={movementList}
        pagination={false}
        onChange={onChangeFilter}
      />
      <Pagination
        current={currentPage}
        pageSize={pagination.perPage}
        total={pagination.total}
        onChange={(page) => onPagination(page)}
        style={{textAlign: "end", marginTop: "10px"}}
        showSizeChanger={false}
      />
      <MovementDetails
        isVisible={showDetails}
        setIsVisible={setShowDetails}
        data={paymentDetails}
        setData={(elem) => setPaymentDetails(elem)}
      />
      <ModalOperation
        action={actionOperation}
        isVisible={isOpenOperation}
        setIsVisible={(data) => setIsOpenOperation(data)}
        accounts={accountList}
        page={currentPage}
      />
    </Card>
  );
};

export default Stores;
