import React, { useEffect, useState } from "react";
import { Button, Form, Input, Modal, Row, Col, Select } from "antd";
import { useAuth } from "../../context/auth-context";
import UploadFile from "../../components/UploadFile/UploadFile";
import { useProducts } from "../../context/products/useProducts";
import { ReactComponent as DrinkerLogo } from "../../assets/images/drinker_small_logo.svg";

const FormItem = Form.Item;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 12 },
    lg: { span: 9 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 12 },
    lg: { span: 15 },
  },
};
const ModalProduct = ({ action = "create", setIsVisible, isVisible }) => {
  const [form] = Form.useForm();
  const [errorsList] = useState([]);
  const {
    selectedProduct,
    setSelectedProduct,
    createOrUpdateProduct,
    createOrUpdateAdminProduct,
    isLoading,
    getAvailableProducts,
    availableProducts,
  } = useProducts();
  const { isAdmin } = useAuth();
  const { name, product, price, description, photo, product_info } = selectedProduct;
  const [productImage, setProductImage] = useState(null);
  const [currentPhoto, setCurrentPhoto] = useState("");
  const [beerOptions, setBeerOptions] = useState([]);

  const titles = {
    edit: "Editar",
    create: "Nuevo",
  };

  // createUser
  const onFinishCreate = async (values) => {
    if(isAdmin){
      await createOrUpdateAdminProduct({ payload: { ...values, productImage } });
    }else {
      await createOrUpdateProduct({ payload: { ...values } });
    }
    setIsVisible(false);
    setSelectedProduct({});
    setCurrentPhoto("");
  };

  //editUser
  const onFinishEdit = async (values) => {
    if(isAdmin){
      await createOrUpdateAdminProduct({
        payload: { ...values, productImage },
        update: true,
        productId: selectedProduct.id,
      });
    } else {
      await createOrUpdateProduct({
        payload: { ...values },
        update: true,
        productId: selectedProduct.id,
      });
    }
    setIsVisible(false);
    setSelectedProduct({});
    setCurrentPhoto("");
  };

  const onSelectProduct = (value) => {
    const image = beerOptions.find((opt)=>opt.value === value).img;
    setCurrentPhoto(image);
    form.setFieldsValue({ image: image })
    form.setFieldsValue({ product: value })
  };

  useEffect(() => {
    if(!availableProducts.length > 0 && !isAdmin){
      getAvailableProducts();
    }
  },[]);

  useEffect(() => {
    form.resetFields();
  }, [isVisible]);

  useEffect(() => {
    form.setFields(errorsList);
  }, [errorsList]);
  useEffect(() => {
    form.setFieldsValue({
      name,
      product,
      price,
      description,
    });
  }, [selectedProduct]);

  useEffect(() => {
    if(photo){
      setCurrentPhoto(photo);
    }
  },[photo])

  useEffect(() => {
    if(product_info){
      setCurrentPhoto(product_info.photo)
    }
  }, [product_info])

  useEffect(()=>{
    if(product_info){
      if(availableProducts){
        const beers = availableProducts.map((elem)=>({
          value: elem.id,
          label: elem.name,
          img: elem.photo,
        }))
        beers.push({
          value: product,
          label: product_info.name,
          img: product_info.photo,
        });
        setBeerOptions(beers);
      }
    }else {
      if(availableProducts){
        const beers = availableProducts.map((elem)=>({
          value: elem.id,
          label: elem.name,
          img: elem.photo,
        }))
        setBeerOptions(beers);
      }
    }
  },[availableProducts, product_info])

  return (
    <Modal
      visible={isVisible}
      footer={null}
      onCancel={() => {
        setIsVisible(false);
        setSelectedProduct({});
        setCurrentPhoto("");
      }}
      centered
      title={`${titles[action]}  producto`}
    >
      <Form
        form={form}
        name="advanced_search"
        {...formItemLayout}
        className="ant-advanced-search-form"
        onFinish={(values) =>
          action === "create" ? onFinishCreate(values) : onFinishEdit(values)
        }
      >
        {
          isAdmin ? (
            <FormItem
              name="name"
              label={`Nombre`}
              rules={[
                {
                  required: true,
                  message: "Complete los campos.",
                },
              ]}
            >
               <Input autoComplete="off" placeholder="Nombre del producto" />
            </FormItem>
          ) : (
            <FormItem
              name="product"
              label={`Nombre`}
              rules={[
                {
                  required: true,
                  message: "Complete los campos.",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Nombre del producto"
                onChange={(value) =>
                  onSelectProduct(value)
                }
                options={beerOptions}
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              />
            </FormItem>
          )
        }
        { !isAdmin && (
          <FormItem
            name="price"
            label={(
              <span>
                Precio en
                <DrinkerLogo height={13} style={{marginLeft:4, marginBottom:-2}}/>
              </span>
            )}
            rules={[
              {
                required: true,
                message: "Complete los campos.",
              },
              {
                message: "El precio del producto es requerido",
                validator: (_, value) => {
                  if (value > 0 || value === undefined) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject("El precio del producto es requerido");
                  }
                },
              },
            ]}
          >
            <Input autoComplete="none" placeholder="Precio del producto" />
          </FormItem>
        )}
        <FormItem name="image" label={"Imagen del producto"}>
          <UploadFile
            image={currentPhoto}
            onChange={(file) => {
              setProductImage(file);
            }}
            justShow={!isAdmin}
          />
        </FormItem>
        <div className="footer-wrapper-modal">
          <Row className="footer-row-modal gx-mb-3"></Row>
        </div>
        <div className="footer-wrapper-modal">
          <Row
            className="footer-row-modal"
            style={{ justifyContent: "center", width: "100%", margin: 0 }}
          >
            <Col
              lg={11}
              md={11}
              sm={12}
              style={{ display: "flex", justifyContent: "right" }}
            >
              <Button
                type="secondary"
                onClick={() => {
                  form.resetFields();
                  setIsVisible(false);
                  setCurrentPhoto("");
                  setSelectedProduct({});
                }}
              >
                Cancelar
              </Button>
            </Col>
            <Col lg={11} md={11} sm={12}>
              <Button loading={isLoading} htmlType="submit">
                Guardar
              </Button>
            </Col>
          </Row>
        </div>
      </Form>
    </Modal>
  );
};

export default ModalProduct;
