import React, { createContext, useEffect, useState } from "react";
import { useAuth } from "../auth-context";

const ProfileContext = createContext({});

function ProfileProvider({ children }) {
  const [isLoading, setIsLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [storeLocation, setStoreLocation] = useState({});
  const [selectedProfile, setSelectedProfile] = useState({});
  const [schedule, setSchedule] = useState([]);
  const [userPicture, setUserPicture] = useState("");
  const { isAuthenticated } = useAuth();
  const [accountsData, setAccountsData] = useState({});

  useEffect(() => {
    if (!isAuthenticated) {
      setStoreLocation({});
      setSelectedProfile({});
    }
  }, [isAuthenticated]);

  return (
    <ProfileContext.Provider
      value={{
        isLoading,
        setIsLoading,
        storeLocation,
        setStoreLocation,
        selectedProfile,
        setSelectedProfile,
        schedule,
        setSchedule,
        userPicture,
        setUserPicture,
        uploading,
        setUploading,
        accountsData, 
        setAccountsData,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
}

export { ProfileProvider, ProfileContext };
