import React, { createContext, useState } from "react";

const SettingsContext = createContext({});

function SettingsProvider({ children }) {
  const [isLoading, setIsLoading] = useState(false);
  const [currenciesList, setCurrenciesList] = useState([]);

  return (
    <SettingsContext.Provider
      value={{
        isLoading,
        setIsLoading,
        currenciesList,
        setCurrenciesList,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
}

export { SettingsProvider, SettingsContext };
