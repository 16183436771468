import {
  LOCATIONS_API_URL,
  LOGIN_API_URL,
  SIGNUP_API_URL,
  SIGNUP_SUCCESS_MESSAGE,
  STORES,
  ADMIN_STORES,
  ME_API_URL,
  LOGOUT_API_URL,
  USERS_API_URL,
  STORE_SCHEDULE_API_URL,
  PRODUCTS_API_URL,
  PRODUCTS_ADMIN_API_URL,
  PROMOTIONS_API_URL,
  PURCHASES_API_URL,
  BALANCE_API_URL,
  ADMIN_USERS_API_URL,
  PAYMENT_API_URL,
  CURRENCIES_API_URL,
  BALANCE_ADMIN_URL,
  STORES_ACCOUNTS,
  STORES_ADMIN_ACCOUNTS,
  ADMIN_MOVEMENTS,
  PAYMENT_ADMIN_API,
  PAYMENT_ADMIN_ACCOUNT,
  COMMISSIONS_ADMIN,
  PAYMENT_ADMIN_OPERATION,
  FORCE_FUNDING,
} from "./constants";
import { post, get, patch, deleted } from "./http";

export const auth = {
  signIn: async ({ password, username }) => {
    const response = await post({
      url: LOGIN_API_URL,
      config: {
        auth: { username, password },
      },
    });

    if (!response.error) {
      try {
        await sessionStorage.setItem("@token", response.data.token);
      } catch (error) {
        // console.error(error);
      }
    }
    return response;
  },
  logOut: async () => {
    const response = await post({ url: LOGOUT_API_URL });
    if (!response.error) {
      try {
        await sessionStorage.removeItem("@token");
      } catch (err) {
        console.error(err);
      }
    }

    return response;
  },
  signup: async (payload, onPhoneError) => {
    const response = await post({
      url: SIGNUP_API_URL,
      data: payload,
      onError: onPhoneError,
    });
    return response;
  },
  me: async () => {
    const response = await get({ url: ME_API_URL });
    return response;
  },
  getBalance: async () => {
    const response = await get({ url: BALANCE_API_URL });
    return response;
  },
};

export const stores = {
  createStore: async (payload) => {
    const response = await post({
      url: STORES,
      data: payload,
      successMessage: SIGNUP_SUCCESS_MESSAGE,
    });
    return response;
  },

  getStores: async ({page, name, start_date, end_date}) => {
    const response = await get({ 
      url:   ADMIN_STORES,
      config: { params: { page, name, start_date, end_date } }
    });
    return response;
  },

  getStoreData: async (storeId) => {
    const response = await get({ url: STORES + storeId + "/" });
    return response;
  },

  getAccountsData: async() => {
    const response = await get({url: STORES_ACCOUNTS});
    return response;
  },

  getAdminAccountsData: async(storeId) => {
    const response = await get({
      url: STORES_ADMIN_ACCOUNTS,
      config: { params: { store_id:storeId }}
    });
    return response;
  },

  createAccountData: async (payload) => {
    const response = await post({
      url: STORES_ACCOUNTS,
      data: payload,
      successMessage: "La cuenta se creó exitosamente",
    });
    return response;
  },

  deleteAccountData: async ({ accountId }) => {
    const response = await deleted({
      url: STORES_ACCOUNTS + accountId + "/",
      successMessage: "La cuenta se ha eliminado exitosamente",
    });
    return response;
  },

  updateAccountData: async (payload) => {
    const response = await patch({
      url: STORES_ACCOUNTS + payload.id + "/",
      data: payload,
      successMessage: "La cuenta se actualizó exitosamente",
    });
    return response;
  },

  verifyStore: async (storeId, verified = true) => {
    const response = await patch({
      url: STORES + storeId + "/",
      data: { verified },
    });

    return response;
  },

  updateStore: async (storeId, payload) => {
    const response = await patch({
      url: STORES + storeId + "/",
      data: payload,
    });
    return response;
  },

  updateCommissionStore: async (storeId, payload) => {
    const response = await patch({
      url: ADMIN_STORES + storeId + "/",
      data: payload,
    });
    return response;
  },

  getBalance: async ({page, store_name, last_payment_start_date, last_payment_end_date}) => {
    const response = await get({ 
      url: BALANCE_ADMIN_URL,
      config: { params: { page, store_name, last_payment_start_date, last_payment_end_date } }
    });
    return response;
  },

  getMovements: async ({page, username, date_range, start_date, end_date, status, movement_type}) => {
    const response = await get({ 
      url: ADMIN_MOVEMENTS,
      config: { params: { page, username, date_range, start_date, end_date, status, movement_type } }
    });
    return response;
  },
};

export const locations = {
  createLocation: async (payload) => {
    const response = await post({ url: LOCATIONS_API_URL, data: payload });
    return response;
  },
  getLocation: async ({ locId }) => {
    const response = await get({ url: LOCATIONS_API_URL + `${locId}/` });
    return response;
  },
  updateLocation: async ({ locId, payload }) => {
    const response = await patch({
      url: LOCATIONS_API_URL + `${locId}/`,
      data: payload,
      successMessage: "La ubicación se ha actualizado exitosamente",
    });
    return response;
  },
};

export const schedule = {
  getSchedule: async (storeId) => {
    const response = await get({ url: STORE_SCHEDULE_API_URL + storeId + "/" });
    return response;
  },
  updateSchedule: async (storeId, payload) => {
    const response = await patch({
      url: STORE_SCHEDULE_API_URL + storeId + "/",
      data: payload,
      successMessage: "El calendario se ha actualizado exitosamente",
    });
    return response;
  },
};

export const users = {
  getUser: async (userId) => {
    const response = await get({ url: USERS_API_URL + userId + "/" });
    return response;
  },

  uploadUserPhoto: async ({ userId, payload }) => {
    const formData = new FormData();

    formData.append("photo", payload);

    const response = await patch({
      url: USERS_API_URL + userId + "/",
      data: formData,
      successMessage: "La imagen de perfil se ha actualizado exitosamente",
    });
    return response;
  },
  deleteAccountAPI: async (userId) => {
    const formBody = new FormData();
    formBody.append('is_active', false);
    const response = await patch({
      url: `${USERS_API_URL}${userId}/`, 
      config: { headers: { "Content-Type": "multipart/form-data" } },
      data: formBody,
      successMessage: "Los datos de tu cuenta se han eliminado exitosamente"
    });
    return response;
  },
};

export const products = {
  getAdminProducts: async ({ storeId, page, name }) => {
    const response = await get({
      url: PRODUCTS_ADMIN_API_URL,
      config: { params: { store: storeId, page, name } },
    });
    return response;
  },
  getProducts: async ({ storeId, promotions = false, page }) => {
    const response = await get({
      url: promotions ? PROMOTIONS_API_URL : PRODUCTS_API_URL,
      config: { params: { store: storeId, page } },
    });
    return response;
  },
  getAvailableProducts:async () => {
    const response = await get({
      url: PRODUCTS_API_URL+"available/",
    });
    return response;
  },
  createOrUpdateProduct: async ({ payload, update = false, productId }) => {
    const formBody = new FormData();
    Object.entries(payload).forEach(([key, value]) => {
      if (value) {
        formBody.append(key, value);
      }
    });
    const response = await (update ? patch : post)({
      url: update ? PRODUCTS_API_URL + productId + "/" : PRODUCTS_API_URL,
      data: formBody,
      config: { headers: { "Content-Type": "multipart/form-data" } },
      successMessage: `El producto se ha ${
        update ? "actualizado" : "creado"
      } exitosamente`,
    });
    return response;
  },
  createOrUpdateAdminProduct: async ({ payload, update = false, productId }) => {
    const formBody = new FormData();
    Object.entries(payload).forEach(([key, value]) => {
      if (value) {
        formBody.append(key, value);
      }
    });
    const response = await (update ? patch : post)({
      url: update ? PRODUCTS_ADMIN_API_URL + productId + "/" : PRODUCTS_ADMIN_API_URL,
      data: formBody,
      config: { headers: { "Content-Type": "multipart/form-data" } },
      successMessage: `El producto se ha ${
        update ? "actualizado" : "creado"
      } exitosamente`,
    });
    return response;
  },
  createOrUpdatePromotion: async ({ payload, update = false, productId }) => {
    const formBody = new FormData();
    Object.entries(payload).forEach(([key, value]) => {
      if (value) {
        formBody.append(key, value);
      }
    });
    const response = await (update ? patch : post)({
      url: update ? PROMOTIONS_API_URL + productId + "/" : PROMOTIONS_API_URL,
      data: formBody,
      config: { headers: { "Content-Type": "multipart/form-data" } },
      successMessage: `La promocion se ha ${
        update ? "actualizado" : "creado"
      } exitosamente`,
    });
    return response;
  },

  deleteProduct: async ({ productId }) => {
    const response = await deleted({
      url: PRODUCTS_API_URL + productId + "/",
      successMessage: "El producto se ha eliminado exitosamente",
    });
    return response;
  },

  deleteAdminProduct: async ({ productId }) => {
    const response = await deleted({
      url: PRODUCTS_ADMIN_API_URL + productId + "/",
      successMessage: "El producto se ha eliminado exitosamente",
    });
    return response;
  },

  deletePromotion: async ({ promotionId }) => {
    const response = await deleted({
      url: PROMOTIONS_API_URL + promotionId + "/",
      successMessage: "La promocion se ha eliminado exitosamente",
    });
    return response;
  },
};

export const purchases = {
  getPurchases: async ({page, status}) => {
    const response = await get({ 
      url: PURCHASES_API_URL, 
      config: { params: { page, status } } 
    });
    return response;
  },

  markAsDelivered: async ({ purchaseId }) => {
    const response = await patch({
      url: PURCHASES_API_URL + purchaseId + "/",
      data: { status: "DELIVERED", claimed: true },
    });
    return response;
  },
};

export const adminUsers = {
  getUsers: async () => {
    const response = await get({ url: ADMIN_USERS_API_URL });
    return response;
  },

  createUser: async ({ payload }) => {
    const response = await post({
      url: ADMIN_USERS_API_URL,
      data: payload,
      successMessage: "Usuario creado exitosamente",
    });
    return response;
  },

  updateUser: async (payload) => {
    const { id, ...rest } = payload;
    const formData = new FormData();
    Object.keys(rest).forEach(key => {
      formData.append(key, rest[key]);
    });
    const response = await patch({
      url: USERS_API_URL + id + "/",
      data: formData,
      successMessage: "Usuario editado exitosamente",
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return response;
  },

  deleteUser: async (userId) => {
    const response = await deleted({
      url: ADMIN_USERS_API_URL + userId + "/",
      successMessage: "El usuario se ha eliminado exitosamente",
    });
    return response;
  },
};

export const payment = {
  postStorePayment: async (payload) => {
    const formBody = new FormData();
    Object.entries(payload).forEach(([key, value]) => {
      if (value) {
        formBody.append(key, value);
      }
    });

    const response = await post({ url: PAYMENT_API_URL, data: formBody });
    return response;
  },
  patchStorePayment: async (payload) => {
    const formBody = new FormData();

    formBody.append("receipt", payload.receipt);

    const response = await patch({
      url: PAYMENT_API_URL + payload.store + "/",
      data: formBody,
    });
    return response;
  },

  getPayments: async () => {
    const response = await get({ url: PAYMENT_API_URL });
    return response;
  },

  getPaymentByStoreId: async ({ storeId, page, start_date, end_date }) => {
    const response = await get({
      url: PAYMENT_ADMIN_API,
      config: { params: { store_id: storeId, page, start_date, end_date } },
    });
    return response;
  },

  getFundsAccount: async ({page} = {}) => {
    const params = page ? { page } : {};
    const response = await get({
      url: PAYMENT_ADMIN_ACCOUNT,
      config: { params },
    });
    return response;
  },

  getAdminCommissionsData: async () => {
    const response = await get({url: COMMISSIONS_ADMIN});
    return response;
  },

  getPendingPaymentData: async (storeId) => {
    const response = await get({
      url: `${PAYMENT_ADMIN_API}pending/${storeId}/`,
    });
    return response;
  },

  createPayment: async (payload) => {
    const formBody = new FormData();
    Object.entries(payload).forEach(([key, value]) => {
      if (value) {
        formBody.append(key, value);
      }
    });

    const response = await post({ url: PAYMENT_ADMIN_API, data: formBody });
    return response;
  },

  getPaymentDetailsData: async (paymentid) => {
    const response = await get({
      url: `${PAYMENT_ADMIN_API}${paymentid}/`,
    });
    return response;
  },
  
  createAdminAccount: async (payload) => {
    const response = await post({
      url: PAYMENT_ADMIN_ACCOUNT,
      data: payload,
      successMessage: "Cuenta creada exitosamente",
    });
    return response;
  },

  createAdminOperation: async (payload) => {
    const response = await post({
      url: PAYMENT_ADMIN_OPERATION,
      data: payload,
      successMessage: "Operación creada exitosamente",
    });
    return response;
  },

  forceFailedCustomerFunding: async (payload) => {
    const response = await patch({
      url: FORCE_FUNDING + payload.fundingId + "/",
    });
    return response;
  },
};

export const settings = {
  getCurrencies: async () => {
    const response = await get({url: CURRENCIES_API_URL,});
    return response;
  },

  createCurrency: async (payload) => {
    const response = await post({ 
      url: CURRENCIES_API_URL, 
      data: payload,
      successMessage: "La moneda se ha creada exitosamente",
     });
    return response;
  },

  updateCurrency: async (currencyId, payload) => {
    const response = await patch({
      url: CURRENCIES_API_URL + currencyId + "/",
      data: payload,
      successMessage: "La moneda se ha actualizado exitosamente",
    });
    return response;
  },

  deleteCurrency: async ({ currencyId }) => {
    const response = await deleted({
      url: CURRENCIES_API_URL + currencyId + "/",
      successMessage: "La moneda se ha eliminado exitosamente",
    });
    return response;
  },

}
