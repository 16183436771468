import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Row, List } from "antd";
import { useAuth } from "../../context/auth-context";
import { usePurchase } from "../../context/purchases/usePurchase";
import { UsdFormatter } from "../../util/usdFormatter";

const OrderDetail = ({
  action = "create",
  setIsVisible,
  isVisible,
  canBeDispatched,
}) => {
  const { selectedPurchase } = usePurchase();
  const { products, amount, reference } = selectedPurchase;
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const { isAdmin } = useAuth();

  useEffect(() => {
    form.resetFields();
    if (action !== "create") {
      form.setFieldsValue({
        name: "Promotion name",
        price: 8,
        description: "Promotion description",
      });
    }
  }, [action]);

  const dispatchOrder = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      setIsVisible(false);
    }, 2000);
  };

  return (
    <Modal
      visible={isVisible}
      footer={null}
      onCancel={() => setIsVisible(false)}
      centered
      title={`Orden #${reference}`}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <List
          size="small"
          bordered
          dataSource={products}
          renderItem={(
            {
              quantity,
              product__name: name,
              product__id: id,
              product__price: price,
            },
            idx
          ) => (
            <>
              <List.Item key={id}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      flexGrow: 1.2,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <span>{name}</span>
                  </div>
                  <span
                    style={{
                      flexGrow: 1,
                      textAlign: "center",
                    }}
                  >
                    <span>{quantity}</span>
                  </span>
                  <div
                    style={{
                      flexGrow: 1,
                      justifyContent: "center",
                      display: "flex",
                    }}
                  >
                    <span>
                      <UsdFormatter price={price} />
                    </span>
                  </div>
                </div>
              </List.Item>
              {idx === products.length - 1 && (
                <List.Item key={id}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        flexGrow: 2.5,
                        display: "flex",
                        alignItems: "center",
                      }}
                    ></div>
                    <span
                      style={{
                        flexGrow: 1,
                        textAlign: "center",
                        fontWeight: 600,
                      }}
                    >
                      Total
                    </span>
                    <div
                      style={{
                        flexGrow: 1,
                        justifyContent: "center",
                        display: "flex",
                        fontWeight: 600,
                      }}
                    >
                      <span>
                        <UsdFormatter price={amount} />
                      </span>
                    </div>
                  </div>
                </List.Item>
              )}
            </>
          )}
        />
      </div>
      <div className="footer-wrapper-modal">
        <Row className="footer-row-modal gx-mb-3"></Row>
      </div>
      <div className="footer-wrapper-modal">
        {canBeDispatched && isAdmin && (
          <Row
            className="footer-row-modal"
            style={{ justifyContent: "center", width: "100%", margin: 0 }}
          >
            <Button
              loading={isLoading}
              htmlType="submit"
              type="primary"
              onClick={dispatchOrder}
            >
              Despachar
            </Button>
          </Row>
        )}
      </div>
    </Modal>
  );
};

export default OrderDetail;
