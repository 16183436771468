import moment from "moment";
import { useContext } from "react";
import { useLocation } from "react-router-dom";
import { locations, schedule, stores, users } from "../../api/api";
import { useAuth } from "../auth-context";
import { ProfileContext } from "./profile-context";

const useProfile = () => {
  const {
    isLoading,
    setIsLoading,
    storeLocation,
    setStoreLocation,
    selectedProfile,
    setSelectedProfile,
    schedule: scheduleData,
    setSchedule,
    userPicture,
    setUploading,
    uploading,
    accountsData, 
    setAccountsData,
  } = useContext(ProfileContext);
  const { user, isAdmin } = useAuth();

  const { state } = useLocation();

  const getLocation = async (location) => {
    const { data, error } = await locations.getLocation({
      locId: location,
    });
    if (!error) {
      const { latitude, longitude } = data;
      setStoreLocation({
        latitude: Number(latitude),
        longitude: Number(longitude),
      });
    }
  };

  const getSchedule = async (storeId) => {
    const { data, error } = await schedule.getSchedule(storeId);
    if (!error) {
      setSchedule(data.results);
    }
  };

  const getUser = async () => {
    setIsLoading(true);
    setUploading(true);
    let userId = state ? state.store.user : user.id;
    const { data, error } = await users.getUser(userId);
    if (!error) {
      await Promise.all([
        getLocation(data.store.location),
        getSchedule(data.store.id),
      ]);
      setSelectedProfile(data);
    }
    setIsLoading(false);
    setUploading(false);
  };

  const updateProfile = async (payload) => {
    setIsLoading(true);
    const { error } = await stores.updateStore(
      selectedProfile.id,
      payload
    );
    if (!error) {
      getStore();
    } else {
      setIsLoading(false);
    }
  };

  const updateCommissionStore = async (payload) => {
    setIsLoading(true);
    await stores.updateCommissionStore(
      selectedProfile.id,
      payload
    );
    setIsLoading(false);
  };

  const updateLocation = async (payload) => {
    setIsLoading(true);
    const { error } = await locations.updateLocation({
      locId: selectedProfile.location.id,
      payload,
    });
    if (!error) {
      await getLocation(selectedProfile.location.id);
    }
    setIsLoading(false);
  };

  const updateSchedule = async () => {
    setIsLoading(true);
    await schedule.updateSchedule(selectedProfile.id, scheduleData);
    setIsLoading(false);
  };

  const onChangeSchedule = ({ value, day, openHour, closed }) => {
    setSchedule((prev) => {
      const aux = prev.slice();
      if (closed !== undefined) {
        aux[day].closed = closed;
        return aux;
      }
      if (openHour) {
        aux[day].open_hour = moment(value, ["h:mm A"]).format("HH:mm:ss");
      } else {
        aux[day].close_hour = moment(value, ["h:mm A"]).format("HH:mm:ss");
      }
      return aux;
    });
  };

  const uploadUserPicture = async (payload) => {
    setUploading(true);
    let userId = state ? state.store.user : user.id;
    const { error } = await users.uploadUserPhoto({
      userId,
      payload,
    });

    if (!error) {
      getUser();
    }
  };

  const getStore = async () => {
    setIsLoading(true);
    const storeData = state ? state.store : user.store;
    if(storeData){
      const { data, error } = await stores.getStoreData(storeData.id);
      if (!error) {
        setSelectedProfile(data);
        if(data.location){
          const { latitude, Longitude } = data.location;
          setStoreLocation({
            latitude: Number(latitude),
            longitude: Number(Longitude),
          });
        }
        if(data.scheduleday_set){
          setSchedule(data.scheduleday_set);
        }
      }
    }
    setIsLoading(false);
  };

  const getAccounts = async () => {
    setIsLoading(true);
    if (isAdmin){
      const storeData = state ? state.store : user.store;
      const { data, error } = await stores.getAdminAccountsData(storeData.id);
      if (!error) {
        setAccountsData(data);
      }
    } else {
      const { data, error } = await stores.getAccountsData();
      if (!error) {
        setAccountsData(data);
      }
    }
    setIsLoading(false);
  };

  const createAccount = async (payload) => {
    setIsLoading(true);
    const { error } = await stores.createAccountData(payload);
    if (!error) {
      await getAccounts();
    } else {
      setIsLoading(false);
    }
  };

  const deleteAccount = async ({ accountId }) => {
    setIsLoading(true);
    const { error } = await stores.deleteAccountData({ accountId });
    if (!error) {
      setAccountsData(currentAccountList => ({
        ...currentAccountList,
        results: currentAccountList.results.filter(account => account.id !== accountId)
      }));
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const updateAccount = async (payload) => {
    setIsLoading(true);
    const { error } = await stores.updateAccountData(payload);
    if (!error) {
      await getAccounts();
    } else {
      setIsLoading(false);
    }
  }

  return {
    isLoading,
    getLocation,
    storeLocation,
    selectedProfile,
    userPicture,
    getUser,
    updateProfile,
    updateLocation,
    scheduleData,
    onChangeSchedule,
    updateSchedule,
    uploadUserPicture,
    uploading,
    getStore,
    getAccounts,
    accountsData,
    deleteAccount,
    createAccount,
    updateAccount,
    updateCommissionStore,
  };
};

export { useProfile };
