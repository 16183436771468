import { useContext } from "react";
import { products } from "../../api/api";
import { useAuth } from "../auth-context";
import { ProductsContext } from "./products-context";

const useProducts = () => {
  const {
    isLoading,
    setIsLoading,
    productList,
    setProductList,
    selectedProduct,
    setSelectedProduct,
    promotionList,
    setPromotionList,
    pagination, 
    setPagination,
    availableProducts,
    setAvailableproducts,
  } = useContext(ProductsContext);
  const { user } = useAuth();

  const getAdminProducts = async ({ page, filters } = {}) => {
    setIsLoading(true);
    const { data, error } = await products.getAdminProducts({
      storeId: user.store ? user.store.id : undefined,
      page,
      name: filters ? filters.name : undefined,
    });
    if (!error) {
      setPagination({
        current: page,
        perPage: 15,
        total: data.count,
      })
      setProductList(data.results);
    }
    setIsLoading(false);
  };

  const getProducts = async ({ page, filters, promotions } = {}) => {
    setIsLoading(true);
    const { data, error } = await products.getProducts({
      storeId: user.store ? user.store.id : undefined,
      promotions,
      page,
    });
    if (!error) {
      if (promotions) {
        setPromotionList(data.results);
      } else {
        setProductList(data.results);
      }
      setPagination({
        current: page,
        perPage: 15,
        total: data.count,
      })
    }

    setIsLoading(false);
  };

  const getAvailableProducts = async () => {
    setIsLoading(true);
    const { data, error } = await products.getAvailableProducts();
    if (!error) {
      setAvailableproducts(data.results);
    }
    setIsLoading(false);
  };

  const createOrUpdateAdminProduct = async ({
    payload,
    update = false,
    productId,
  }) => {
    setIsLoading(true);
    const { image, productImage, ...rest } = payload;
    const { data, error } = await products.createOrUpdateAdminProduct({
      payload: {
        ...rest,
        storeId: user.store ? user.store.id : undefined,
        photo: productImage,
      },
      update,
      productId,
    });
    if (!error) {
      if(update){
        const updatedProductList = productList.map((product) => {
          if (product.id === productId) {
            return data;
          }
          return product;
        });
        setProductList(updatedProductList);
        setIsLoading(false);
      } else {
        await getAdminProducts({page:1});
      }
    } else {
      setIsLoading(false);
    }
  };

  const createOrUpdateProduct = async ({
    payload,
    update = false,
    productId,
  }) => {
    setIsLoading(true);
    const { image, ...rest } = payload;
    const { data, error } = await products.createOrUpdateProduct({
      payload: {
        ...rest,
        storeId: user.store ? user.store.id : undefined,
      },
      update,
      productId,
    });
    if (!error) {
      if(update){
        const updatedProductList = productList.map((product) => {
          if (product.id === productId) {
            return data;
          }
          return product;
        });
        setProductList(updatedProductList);
        await getAvailableProducts();
        setIsLoading(false);
      } else {
        await getAvailableProducts();
        await getProducts({page:1});
      }
    } else {
      setIsLoading(false);
    }
  };

  const deleteProduct = async ({ productId }) => {
    setIsLoading(true);
    const { error } = await products.deleteProduct({ productId });
    if (!error) {
      await getAvailableProducts();
      setProductList(currentProductList => 
        currentProductList.filter(product => product.id !== productId)
      );
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const deleteAdminProduct = async ({ productId }) => {
    setIsLoading(true);
    const { error } = await products.deleteAdminProduct({ productId });
    if (!error) {
      setProductList(currentProductList => 
        currentProductList.filter(product => product.id !== productId)
      );
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const deletePromotion = async ({ promotionId }) => {
    setIsLoading(true);
    const { error } = await products.deletePromotion({ promotionId });
    if (!error) {
      setPromotionList(currentPromotiontList => 
        currentPromotiontList.filter(promotion => promotion.id !== promotionId)
      );
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const createOrUpdatePromotion = async ({
    payload,
    update = false,
    productId,
  }) => {
    setIsLoading(true);
    const { image, productImage, ...rest } = payload;
    const { data, error } = await products.createOrUpdatePromotion({
      payload: {
        ...rest,
        photo: productImage,
      },
      update,
      productId,
    });
    if (!error) {
      if(update){
        const updatedPromotionList = promotionList.map((promotion) => {
          if (promotion.id === productId) {
            return data;
          }
          return promotion;
        });
        setPromotionList(updatedPromotionList);
        setIsLoading(false);
      } else {
        await getProducts({ promotions: true });
      }
    } else {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    getProducts,
    getAdminProducts,
    getAvailableProducts,
    availableProducts,
    productList,
    selectedProduct,
    setSelectedProduct,
    createOrUpdateProduct,
    createOrUpdateAdminProduct,
    deleteAdminProduct,
    createOrUpdatePromotion,
    promotionList,
    deleteProduct,
    deletePromotion,
    pagination,
  };
};

export { useProducts };
