import { useContext } from "react";
import { stores } from "../../api/api";
import { StoreContext } from "./stores-context";
import moment from "moment";

const useStores = () => {
  const { 
    isLoading, 
    setIsLoading, 
    storeList, 
    setStoreList,
    pagination, 
    setPagination,
    movementList, 
    setMovementList,
  } = useContext(
    StoreContext
  );

  const getStores = async (page, filters) => {
    setIsLoading(true);
    const { data, error } = await stores.getStores({
      page,
      name: filters ? filters.name : undefined,
      start_date: filters ? filters.rangeDate && moment(filters.rangeDate[0]).format('YYYY-MM-DD') : undefined,
      end_date: filters ? filters.rangeDate && moment(filters.rangeDate[1]).format('YYYY-MM-DD')  : undefined,
    });
    if (!error) {
      setPagination({
        current: page,
        perPage: 15,
        total: data.count,
      })
      setStoreList(data.results.reverse());
    }
    setIsLoading(false);
  };

  const getMovements = async (page, filters) => {
    setIsLoading(true);
    const { data, error } = await stores.getMovements({
      page,
      username: filters ? filters.username : undefined,
      start_date: filters ? filters.rangeDate && moment(filters.rangeDate[0]).format('YYYY-MM-DD') : undefined,
      end_date: filters ? filters.rangeDate && moment(filters.rangeDate[1]).format('YYYY-MM-DD')  : undefined,
      status: filters ? filters.status : undefined,
      movement_type: filters ? filters.movement_type : undefined,
    });
    if (!error) {
      setPagination({
        current: page,
        perPage: 15,
        total: data.count,
      })
      setMovementList(data.results);
    }
    setIsLoading(false);
  };

  const verifyStore = async (storeId, verified) => {
    setIsLoading(true);
    const { error } = await stores.verifyStore(storeId, verified);
    if (!error) {
      getStores();
    }
  };

  return {
    isLoading,
    getStores,
    getMovements,
    storeList,
    movementList,
    verifyStore,
    pagination,
  };
};

export { useStores };
