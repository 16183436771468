import React, { useState } from "react";
import { Modal, Table } from "antd";
import { dateFormat, numberFormatter } from "../utils/formatters";
import { ReactComponent as DrinkerLogo } from "../../assets/images/drinker_small_logo.svg";

const InvoiceDetail = ({
  setIsVisible,
  isVisible,
  data,
}) => {

  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const onExpand = (expanded, record) => {
    if (expanded) {
      setExpandedRowKeys((prevKeys) => [...prevKeys, record.id]);
    } else {
      setExpandedRowKeys((prevKeys) => prevKeys.filter((key) => key !== record.id));
    }
  };

  const columns = [
    {
      title: "Fecha ",
      width: 120,
      dataIndex: "created_at",
      key: "created_at",
      render: () => {
        return <span>{dateFormat(data.created_at)}</span>;
      },
    },
    {
      title: "Nro. de orden",
      dataIndex: "reference_number",
      key: "reference_number",
      align: "center",
      render: () => {
        return <span>{data.reference_number}</span>;
      },
    },
    {
      title: (
        <span>
          Monto en
          <DrinkerLogo height={13} style={{marginLeft:4, marginBottom:-2}}/>
        </span>
      ),
      dataIndex: "amount",
      align: "center",
      key: "amount",
      render: (text) => {
        return <span>{numberFormatter(text)}</span>
      }
    },
    {
      title: "Comisión",
      dataIndex: "commission_amount",
      align: "center",
      key: "commission_amount",
      render: (text) => {
        return <span>{numberFormatter(text)}</span>
      }
    },
  ];

  const productColumns = [
    {
      title: "Producto",
      dataIndex: "product__name",
      align: "center",
      key: "product__name",
    },
    {
      title: "Cantidad",
      dataIndex: "quantity",
      align: "center",
      key: "quantity",
    },
  ];
  const promotionColumns = [
    {
      title: "Promoción",
      dataIndex: "promotion__title",
      align: "center",
      key: "promotion__title",
    },
    {
      title: "Cantidad",
      dataIndex: "quantity",
      align: "center",
      key: "quantity",
    },
  ];

  const productList = (record) => {
    return (
      <>
        {record.products && record.products.length > 0 && (
          <Table
            dataSource={record.products}
            columns={productColumns}
            pagination={false}
            size="small"
          />
        )}
        {record.promotions && record.promotions.length > 0 && (
          <Table
            dataSource={record.promotions}
            columns={promotionColumns}
            pagination={false}
            size="small"
          />
        )}
      </>
    )
  }

  return (
    <Modal
      visible={isVisible}
      footer={null}
      onCancel={() => setIsVisible(false)}
      centered
      title={`Pago ${data.reference_number || ""}`}
    >
      <Table
        rowKey="id"
        className="gx-table-responsive custom-table-products"
        columns={columns}
        dataSource={data.purchases}
        expandable={{
          expandedRowRender: (record, idx) => productList(record, idx),
          rowExpandable: (record) => record.products.length > 0 || record.promotions.length > 0,
          expandedRowKeys,
          onExpand,
        }}
        pagination={false}
        size="small"
      />
    </Modal>
  );
};

export default InvoiceDetail;
