import { useContext } from "react";
import { purchases } from "../../api/api";
import { PurchasesContext } from "./purchases-context";

const usePurchase = () => {
  const {
    isLoading,
    setIsLoading,
    purchasesList,
    setPurchasesList,
    selectedPurchase,
    setSelectedPurchase,
    pagination, 
    setPagination,
  } = useContext(PurchasesContext);

  const getPurchases = async (page, filters) => {
    setIsLoading(true);
    const { data, error } = await purchases.getPurchases({
      page,
      status: filters.status
    });
    if (!error) {
      setPagination({
        current: page,
        perPage: 15,
        total: data.count,
      })
      setPurchasesList(
        data.results
          .map(({ store_info, user_info, ...rest }) => ({
            ...rest,
            store_name: store_info.name,
            client_name: user_info.profile.name,
          }))
          .filter((order) => {
            if (!order.gift_status) {
              return true;
            }
            if (order.gift_status === "CLAIMED") {
              return true;
            }
            return false;
          })
      );
    }
    setIsLoading(false);
  };

  const markAsDeliverd = async ({ purchaseId }) => {
    setIsLoading(true);
    const { error } = await purchases.markAsDelivered({ purchaseId });
    if (!error) {
      getPurchases(pagination.current, {});
    }
  };

  return {
    isLoading,
    getPurchases,
    markAsDeliverd,
    purchasesList,
    setSelectedPurchase,
    selectedPurchase,
    pagination,
  };
};

export { usePurchase };
