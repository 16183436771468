import React from "react";
import ReactDOM from "react-dom";

import NextApp from "./NextApp";
import registerServiceWorker from "./registerServiceWorker";
import "antd/dist/antd.css";
import "./styles/views/index";
import AppProviders from "./context/AppProviders";
// Add this import:
import { AppContainer } from "react-hot-loader";

//To avoid warning in console in forms https://github.com/yiminghe/async-validator#how-to-avoid-warning
import Schema from "async-validator";
Schema.warning = function() {};
// Wrap the rendering in a function:
const render = (Component) => {
  ReactDOM.render(
    // Wrap App inside AppContainer
    <AppProviders>
      <AppContainer>
        <Component />
      </AppContainer>
    </AppProviders>,
    document.getElementById("root")
  );
};

// Do this once
registerServiceWorker();

// Render once
render(NextApp);

// Webpack Hot Module Replacement API
if (module.hot) {
  module.hot.accept("./NextApp", () => {
    render(NextApp);
  });
}
