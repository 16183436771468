import React, { createContext, useContext, useState } from "react";
import { useHistory } from "react-router";
import { auth, locations, users } from "../api/api";

const AuthContext = createContext();

function AuthProvider({ children }) {
  const [isLoading, setIsLoading] = useState(false);
  const [gettingCurrentUser, setGettingCurrentUser] = useState(true);
  const [storeBalance, setStoreBalance] = useState(0);
  const [userData, setUserData] = useState({
    token: null,
    user: null,
  });

  return (
    <AuthContext.Provider
      value={{
        userData,
        setUserData,
        isLoading,
        setIsLoading,
        gettingCurrentUser,
        setGettingCurrentUser,
        storeBalance,
        setStoreBalance,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

const useAuth = () => {
  const {
    userData,
    setUserData,
    isLoading,
    setIsLoading,
    setGettingCurrentUser,
    gettingCurrentUser,
    storeBalance,
    setStoreBalance,
  } = useContext(AuthContext);
  const isAuthenticated = !!userData.token;
  const history = useHistory();

  const getStoreBalance = async () => {
     const { error, data } = await auth.getBalance();
     if (!error) {
       setStoreBalance(data.balance);
     }
  };

  const me = async () => {
    setGettingCurrentUser(true);
    const { error, data } = await auth.me();
    if (!error) {
      if(data.store){
        getStoreBalance();
      }
      setUserData((prev) => ({
        ...prev,
        user: { ...data, isSuperuser: !data.store },
      }));
    }
    setGettingCurrentUser(false);
  };

  const deleteAccount = async (payload) => {
    setIsLoading(true);
    const { error ,data} = await auth.signIn(payload);
    if (!error) {
      const userId = data.user.id
      const {error: errorDeleteAccount} = await users.deleteAccountAPI(userId)
      // if (data.user.type !== "STR") {
      //   notification.open({
      //     type: "error",
      //     message: 401,
      //     description: "Invalid credentials",
      //   });
      setIsLoading(false);
      return errorDeleteAccount
    } else {
      setIsLoading(false);
      return error;
    }
  };
  const signIn = async (payload) => {
    setIsLoading(true);
    const { error, data } = await auth.signIn(payload);
    if (error === 403) {
      history.push("review");
    }
    if (!error) {
      // if (data.user.type !== "STR") {
      //   notification.open({
      //     type: "error",
      //     message: 401,
      //     description: "Invalid credentials",
      //   });
      getStoreBalance();
      setUserData({
        token: data.token,
        user: {
          ...data.user,
          isSuperuser: !data.user.store,
        },
      });
      setIsLoading(false);
    } else {
      setIsLoading(false);
      return error;
    }
  };

  const logOut = async () => {
    auth.logOut();
    setUserData({});
  };

  const signUp = async (payload, onSucces, onError) => {
    setIsLoading(true);
    try {
      const { lat, lng, phone, username, description, ...rest } = payload;
      const { error, data } = await locations.createLocation({
        latitude: lat,
        longitude: lng,
      });
      if (error) {
        return;
      }
      const { id } = data;
      const response = await auth.signup(
        {
          ...rest,
          username: username.replaceAll(" ", ""),
          type: "STR",
          store: {
            name: username,
            description,
            phone: "+" + (!phone.startsWith("58") ? "58" : "") + phone,
            location: id,
          },
        },
        onError
      );
      if (!response.error && onSucces) {
        onSucces();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isAuthenticated,
    isAdmin: userData.user && userData.user.isSuperuser,
    token: userData.token,
    user: userData.user,
    setUserData,
    isLoading,
    signUp,
    signIn,
    me,
    logOut,
    deleteAccount,
    gettingCurrentUser,
    storeBalance,
  };
};

export { AuthProvider, useAuth, AuthContext };
