import React, { useEffect, useState, useMemo } from "react";
import {
  Button,
  Form,
  Input,
  Modal,
  Row,
  Col,
  Select,
  InputNumber
} from "antd";
import { usePayments } from "../../context/payment/usePayments";
import { useStores } from "../../context/stores/useStores";

const FormItem = Form.Item;
const formItemLayout = {
  labelCol: {
    md: { span: 9 },
  },
  wrapperCol: {
    md: { span: 24 },
  },
};
const ModalOperation = ({ action = "transfer", setIsVisible, isVisible, accounts, page }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [accountOptions, setAccountOptions] = useState([]);
  const [form] = Form.useForm();
  const [accountCurrency, setAccountCurrency] = useState({origin: "", destination: ""});
  const {getMovements} = useStores();
  const {createOperation} = usePayments();

  useEffect(() => {
    form.resetFields();
  }, [isVisible]);

  useEffect(() => {
    if(accounts.length > 0){
      const accountsToShow = accounts.filter((elem) => elem.name !== "Drinker" );
      setAccountOptions(accountsToShow);
    }
    
  },[accounts])

  const titles = {
    transfer: "Transferencia entre cuentas",
    recharge: "Recarga",
    withdrawal: "Retiro"
  };

  const onFinishTransfer = async (values) => {
    if (values.origin === values.destination) {
        form.setFields([
          {
            name: 'destination',
            errors: ['La cuenta de origen y destino no pueden ser la misma'],
          },
        ]);
        return;
    }
    setIsLoading(true);
    const payloadCommission = {
      origin_account: values.origin,
      destination_account: values.destination,
      amount: values.amount,
      commission: values.commission
    };
    const payloadRate = {
      origin_account: values.origin,
      destination_account: values.destination,
      amount: values.amount,
      usd_exchange_rate: values.exchangeRate,
    };
    const payload = (accountCurrency.origin === "VES" || accountCurrency.destination === "VES") ? payloadRate : payloadCommission;
    const {success} = await createOperation(payload);
    if(success){
      getMovements(page, {});
    };
    setIsLoading(false);
    setIsVisible(false);
  };

  const onFinishDeposit = async (values) => {
    setIsLoading(true);
    const payloadRecharge = {
        destination_account: values.destination,
        amount: values.amount
    };
    const payloadWithdrawal = {
      origin_account: values.origin,
      amount: values.amount*(-1),
    };
    const payload = action === "recharge" ? payloadRecharge : payloadWithdrawal;
    const {success} = await createOperation(payload);
    if(success){
      getMovements(page, {});
    };
    setIsLoading(false);
    setIsVisible(false);
  };

  const onSelectAccount = (name, value) => {
    const selectedAccount = accountOptions.find((elem) => elem.id === value);
    setAccountCurrency((prev) => ({...prev, [name]:selectedAccount.currency}))
    form.setFieldsValue({ [name]: value, currency: selectedAccount.currency })
  };

  const onCancel = () => {
    form.resetFields();
    setAccountCurrency({origin: "", destination: ""})
    setIsVisible(false); 
  };

  const onSelectCurrency = (value) => {
    form.setFieldsValue({ currency: value })
  };

  const renderLabelAmount = useMemo(() => {
    if(!accountCurrency.origin && !accountCurrency.destination){
      return 'Monto'
    }
    if (accountCurrency.origin){
      if(accountCurrency.origin === 'VES'){
        return 'Monto en Bs'
      }
      return 'Monto en $'
    } 
    if (accountCurrency.destination){
      if(accountCurrency.destination === 'VES'){
        return 'Monto en Bs'
      }
      return 'Monto en $'
    } 
  },[accountCurrency])

  return (
    <Modal
      visible={isVisible}
      footer={null}
      onCancel={onCancel}
      centered
      title={`${titles[action]}`}
    >
      <Form
        form={form}
        name="advanced_search"
        {...formItemLayout}
        className="ant-advanced-search-form"
        onFinish={(values) =>
          action === "transfer" ? onFinishTransfer(values) : onFinishDeposit(values)
        }
      >

        { action !== "recharge" && (
          <FormItem
            name="origin"
            label={`Cuenta de origen`}
            rules={[
              {
                required: true,
                message: "Complete los campos.",
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Seleccione la cuenta"
              onChange={(value) =>{
                onSelectAccount("origin", value);
                form.validateFields(['origin', 'destination']);
              }}
              options={accountOptions.map((account) => (
                  {label: account.name, value: account.id}
              ))}
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            />
          </FormItem>
        )}
        { action !== "withdrawal" && (
          <FormItem
            name="destination"
            label={`Cuenta de destino`}
            rules={[
              {
                required: true,
                message: "Complete los campos.",
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Seleccione la cuenta"
              onChange={(value) =>{
                  onSelectAccount("destination", value);
                  form.validateFields(['origin', 'destination']);
              }}
              options={accountOptions.map((account) => (
                  {label: account.name, value: account.id}
              ))}
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            />
          </FormItem>
        )}              
        <FormItem
          name="amount"
          label={renderLabelAmount}
          rules={[
            {
              required: true,
              message: "Complete los campos.",
            },
            {
              validator: (_, value) => {
                if (value > 0) {
                  return Promise.resolve();
                } else {
                  return Promise.reject("El monto debe ser mayor a 0.");
                }
              },
            },
          ]}
        >
          <Input autoComplete="none" placeholder="Monto" />
        </FormItem>
        { action === "transfer" ? (
          (accountCurrency.origin === "VES" || accountCurrency.destination === "VES") ? (
            <FormItem
              label="Tasa de cambio"
              name="exchangeRate"
              rules={[
                {
                  required: true,
                  message: "Complete los campos.",
                },
                {
                  validator: (_, value) =>
                    value > 0 ? Promise.resolve() : Promise.reject(new Error('La tasa debe ser un número mayor a cero.')),
                },
              ]}
            >
              <InputNumber 
                placeholder="Tasa del día" 
                style={{ width: "100%" }} 
                min={0.01}
              />
            </FormItem>
          ) : (
            <FormItem
              label="Comisión"
              name="commission"
              rules={[
                {
                  required: true,
                  message: "Complete los campos.",
                },
              ]}
            >
              <InputNumber 
                placeholder="Comisión" 
                style={{width: "100%" }} 
                min={0.00}
              />
            </FormItem>
          )
        ): (
          <FormItem
            name="currency"
            label={`Moneda`}
            rules={[
              {
                required: true,
                message: "Complete los campos.",
              },
            ]}
          >
            <Select
              disabled
              placeholder="Seleccione la moneda"
              onChange={(value) =>
                onSelectCurrency(value)
              }
              options={[
                  {value: 'VES', label: "Bolivares"},
                  {value: 'USD', label: "Dolares"},
              ]}
            />
          </FormItem>
        )}
        <div className="footer-wrapper-modal">
          <Row
            className="footer-row-modal"
            style={{ justifyContent: "center", width: "100%", margin: 0 }}
          >
            <Col
              lg={11}
              md={11}
              sm={12}
              style={{ display: "flex", justifyContent: "right" }}
            >
              <Button
                type="secondary"
                onClick={onCancel}
              >
                Cancelar
              </Button>
            </Col>
            <Col lg={11} md={11} sm={12}>
              <Button loading={isLoading} htmlType="submit">
                Guardar
              </Button>
            </Col>
          </Row>
        </div>
      </Form>
    </Modal>
  );
};

export default ModalOperation;
