import React, { useEffect, useState } from "react";
import { Button, Form, Input, Modal, Row, Col } from "antd";
import { SolutionOutlined, UserOutlined } from "@ant-design/icons";
import withLoaderButton from "../../hocs/StyledButton";
import { useUsers } from "../../context/Users/useUsers";

const FormItem = Form.Item;
const validateString = (string) => /^[a-zA-Z\u00C0-\u017F\s]*$/.test(string);
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 12 },
    lg: { span: 9 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 12 },
    lg: { span: 15 },
  },
};
const LoaderButton = withLoaderButton(Button);

const ModalUser = ({ action = "create", userSelected }) => {
  const [form] = Form.useForm();
  const [errorsList] = useState([]);
  const { createUser, updateUser, isLoading, modalVisible, setModalVisible } = useUsers();

  const titles = {
    edit: "Editar",
    create: "Nueva",
  };

  useEffect(() => {
    form.setFields(errorsList);
  }, [errorsList]);

  useEffect(() => {
    if(userSelected){
      form.setFieldsValue({
        email: userSelected.email,
        firstName: userSelected.first_name,
        lastName: userSelected.last_name,
      });
    }
  }, [userSelected]);

  const onFinishCreate = async (values) => {
    await createUser(values);
  };

  const onFinishEdit = async (values) => {
    await updateUser({...values, id: userSelected.id});
  };

  const validateStringForm = (_, inputValue) => {
    if (!inputValue) return Promise.reject(new Error("Complete los campos"));
    else if (validateString(inputValue)) {
      return Promise.resolve();
    } else return Promise.reject(new Error("Introduzca solo letras"));
  };

  return (
    <Modal
      visible={modalVisible}
      footer={null}
      onCancel={() => {setModalVisible(false); form.setFieldsValue({}) }}
      centered
      title={`${titles[action]}  usuario`}
    >
      <Form
        form={form}
        name="advanced_search"
        {...formItemLayout}
        className="ant-advanced-search-form"
        onFinish={(values) =>
          action === "create" ? onFinishCreate(values) : onFinishEdit(values)
        }
      >
        <FormItem
          name="email"
          label={`Correo electrónico`}
          rules={[
            {
              type: "email",
              message: "El correo electrónico no es valido.",
            },
            {
              required: true,
              message: "Complete los campos.",
            },
          ]}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            autoComplete="off"
            placeholder="tucorreo@example.com"
          />
        </FormItem>

        <FormItem
          name="firstName"
          label={`Nombre`}
          rules={[
            {
              validator: validateStringForm,
              required: true,
            },
          ]}
        >
          <Input
            prefix={<SolutionOutlined className="site-form-item-icon" />}
            autoComplete="none"
            placeholder="Nombre del usuario"
          />
        </FormItem>
        <FormItem
          name="lastName"
          label={`Apellido`}
          rules={[
            {
              validator: validateStringForm,
              required: true,
            },
          ]}
        >
          <Input
            prefix={<SolutionOutlined className="site-form-item-icon" />}
            autoComplete="none"
            placeholder="Apellido del usuario"
          />
        </FormItem>
        <div className="footer-wrapper-modal">
          <Row className="footer-row-modal gx-mb-3"></Row>
        </div>
        <div className="footer-wrapper-modal">
          <Row className="footer-row-modal">
            <Col lg={11} md={11} sm={12}>
              <Button
                type="secondary"
                onClick={() => {
                  form.resetFields();
                  setModalVisible(false);
                }}
              >
                Cancelar
              </Button>
            </Col>
            <Col lg={11} md={11} sm={12}>
              <LoaderButton isLoading={isLoading}>Guardar</LoaderButton>
            </Col>
          </Row>
        </div>
      </Form>
    </Modal>
  );
};

export default ModalUser;
