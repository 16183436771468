import React, { useEffect, useState } from "react";
import { Divider, Tooltip } from "antd";
import SimpleTable from "../../components/table/Basic/SimpleTable";
import DeleteModal from "../../components/Modal/DeleteModal";
import moment from "moment";
import { dateFormat } from "../../util/formatters";
import ModalPromotion from "./PromotionDetail";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useProducts } from "../../context/products/useProducts";
import { numberFormatter } from "../utils/formatters";
import { ReactComponent as DrinkerLogo } from "../../assets/images/drinker_small_logo.svg";

const Promotions = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [action, setAction] = useState("");
  const {
    promotionList,
    isLoading,
    getProducts,
    setSelectedProduct,
    deletePromotion,
    pagination
  } = useProducts();
  const [currentPage, setCurrentPage] = useState(1);

  const onPagination = (page) => {
    setCurrentPage(page);
  };

  const handleOperatorDelete = async (id) => {
    deletePromotion({ promotionId: id });
  };

  useEffect(() => {
    getProducts({page:currentPage,  promotions: true})
  }, [currentPage]);

  const columns = [
    {
      title: "Fecha de creación",
      dataIndex: "date",
      key: "date",
      defaultSortOrder: "descend",
      sorter: (a, b) =>
        new moment(a.date).format("YYYYMMDDHHmmss") -
        new moment(b.date).format("YYYYMMDDHHmmss"),
      sortDirections: ["descend", "ascend"],
      render: (text, _) => {
        return <span>{dateFormat(text)}</span>;
      },
    },
    {
      title: "Nombre",
      key: "title",
      dataIndex: "title",
      render: (_, record) => {
        return <span>{record.title}</span>;
      },
    },
    {
      title: (
        <span>
          Precio en
          <DrinkerLogo height={13} style={{marginLeft:4, marginBottom:-2}}/>
        </span>
      ),
      key: "names",
      render: (_, record) => (
        <span>
          {numberFormatter(record.price)}
        </span>
      ),
    },
    {
      title: "Acciones",
      key: "action",
      width: 150,
      render: (_, record) => (
        <div
          style={{
            display: "flex",
            gap: 2,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Tooltip title="Editar">
            <EditOutlined
              style={{ fontSize: 20, cursor: "pointer" }}
              onClick={() => {
                setAction("edit");
                setIsVisible(true);
                setSelectedProduct(record);
              }}
            />
          </Tooltip>
          <Divider type="vertical" />
          <Tooltip title="Eliminar">
            <DeleteOutlined
              style={{ fontSize: 20, cursor: "pointer" }}
              onClick={() =>
                DeleteModal(record, "esta promocion", () =>
                  handleOperatorDelete(record.id)
                )
              }
            />
          </Tooltip>
        </div>
      ),
    },
  ];
  return (
    <>
      <SimpleTable
        columns={columns.map((col) => ({ ...col, align: "center" }))}
        loading={isLoading}
        data={promotionList}
        buttonTitle="Nueva promocion"
        onClickModal={() => {
          setAction("create");
          setIsVisible(true);
        }}
        isRolFilter={true}
        paginationData={pagination}
        onPagination={(pag) => onPagination(pag)}
      />

      <ModalPromotion
        action={action}
        isVisible={isVisible}
        setIsVisible={(data) => setIsVisible(data)}
        // allUsers={allUsers}
        // setAllUsers={(data) => setAllUsers(data)}
      />
    </>
  );
};

export default Promotions;
