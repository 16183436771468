import { useContext } from "react";
import { adminUsers } from "../../api/api";
import { UsersContext } from "./users-context";

const useUsers = () => {
  const {
    isLoading,
    setIsLoading,
    usersList,
    setUsersList,
    modalVisible,
    setModalVisible,
  } = useContext(UsersContext);

  const getUsers = async () => {
    setIsLoading(true);
    const { data, error } = await adminUsers.getUsers();
    if (!error) {
      setUsersList(data.results);
    }
    setIsLoading(false);
  };

  const createUser = async (data) => {
    setIsLoading(true);
    const payload = {
      first_name: data.firstName,
      last_name: data.lastName,
      username: `${data.firstName}${data.lastName}`,
      email: data.email,
    };
    try {
      await adminUsers.createUser({ payload: payload });
      getUsers();
      setModalVisible(false);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const updateUser = async (data)=> {
    setIsLoading(true);
    const payload = {
      first_name: data.firstName,
      last_name: data.lastName,
      username: `${data.firstName}${data.lastName}`,
      email: data.email,
      id: data.id
    };
    try {
      const { error } = await adminUsers.updateUser(payload);
      if(!error){
        const updatedUserList = usersList.map((user) => {
          if (user.id === data.id) {
            return payload;
          }
          return user;
        });
        setUsersList(updatedUserList);
      }
      setModalVisible(false);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteUser = async (userId) => {
    setIsLoading(true);
    try {
      await adminUsers.deleteUser(userId);
      getUsers();
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    usersList,
    getUsers,
    deleteUser,
    createUser,
    updateUser,
    modalVisible,
    setModalVisible,
  };
};

export { useUsers };
