import * as React from "react";
import { AuthProvider } from "./auth-context";
import PropTypes from "prop-types";
import { NotificationProvider } from "./notification-context";

function AppProviders({ children }) {
  return (
    <AuthProvider>
      <NotificationProvider>{children}</NotificationProvider>
    </AuthProvider>
  );
}

AppProviders.propTypes = {
  children: PropTypes.any,
};

export default AppProviders;
