import React from "react";
import { Checkbox, Button, Radio } from "antd";

const CheckboxGroup = Checkbox.Group;

export const filterDropdown = (options, setSelectedKeys, selectedKeys, confirm, clearFilters, multiple ) => (
  <div className="custom-dropdown-filter">
    { multiple ? (
        <CheckboxGroup
          options={options}
          value={selectedKeys}
          onChange={(checkedValues) => setSelectedKeys(checkedValues)}
        />
      ):(
        <Radio.Group
          options={options}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys([e.target.value])}
        />
    )}
    <div className="btns-dropdown-filter">
      <Button 
          onClick={() => {clearFilters(); confirm()}} 
          size="small" 
          style={{ marginRight: 8, marginBottom: 10 }}
      >
        Reiniciar
      </Button>
      <Button
        type="primary"
        onClick={() => confirm()}
        size="small"
        style={{marginBottom: 10 }}
      >
        Filtrar
      </Button>
    </div>
  </div>
);