import { useContext } from "react";
import { settings } from "../../api/api";
import { SettingsContext } from "./settings-context";

const useSettings = () => {
  const {
    isLoading,
    setIsLoading,
    currenciesList,
    setCurrenciesList,
  } = useContext(SettingsContext);

  const getCurrencies = async () => {
    setIsLoading(true);
    const { data, error } = await settings.getCurrencies();
    if (!error) {
        setCurrenciesList(data.results);
    }
    setIsLoading(false);
  };


  const createCurrency = async (payload) => {
    setIsLoading(true);
    const { error } = await settings.createCurrency(payload);
    if (!error) {
      await getCurrencies();
    } else {
      setIsLoading(false);
    }
  };

  const updateCurrency = async ({ currencyId, payload }) => {
    setIsLoading(true);
    const { error } = await settings.updateCurrency(currencyId, payload);
    if (!error) {
      await getCurrencies();
    } else {
      setIsLoading(false);
    }
  };

  const deleteCurrency = async ({ currencyId }) => {
    setIsLoading(true);
    const { error } = await settings.deleteCurrency({ currencyId });
    if (!error) {
      setCurrenciesList(currentCurrenciesList => 
        currentCurrenciesList.filter(currency => currency.id !== currencyId)
      );
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    getCurrencies,
    createCurrency,
    updateCurrency,
    deleteCurrency,
    currenciesList,
  };
};

export { useSettings };
