import React, { createContext, useState } from "react";

const UsersContext = createContext({});

function UsersProvider({ children }) {
  const [isLoading, setIsLoading] = useState(false);
  const [usersList, setUsersList] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);

  return (
    <UsersContext.Provider
      value={{
        isLoading,
        setIsLoading,
        usersList,
        setUsersList,
        modalVisible, 
        setModalVisible
      }}
    >
      {children}
    </UsersContext.Provider>
  );
}

export { UsersProvider, UsersContext };