import React, { useEffect, useState } from "react";
import { Card, Table, Divider, Tooltip } from "antd";
import "moment/locale/es";
import moment from "moment";
import { dateFormat } from "../utils/formatters";
import InvoiceDetail from "./InvoiceDetail";
import { DownloadOutlined, EyeOutlined } from "@ant-design/icons";
import { usePayments } from "../../context/payment/usePayments";
import { useDownload } from "../../services/downloadCsv";
import { numberFormatter } from "../utils/formatters";

const Invoices = () => {
  const [isVisible, setIsVisible] = useState(false);
  const {
    getPayments,
    payments,
    setSelectedPayment,
    selectedPayment,
    isLoading,
  } = usePayments();
  const { download } = useDownload();

  const columns = [
    {
      title: "Fecha ",
      width: 120,
      dataIndex: "created_at",
      key: "created_at",
      align: "center",
      defaultSortOrder: "descend",
      sorter: (a, b) =>
        new moment(a.date).format("YYYYMMDDHHmmss") -
        new moment(b.date).format("YYYYMMDDHHmmss"),
      sortDirections: ["descend", "ascend"],
      render: (text, _) => {
        return <span>{dateFormat(text)}</span>;
      },
    },
    {
      title: "Número de pago",
      dataIndex: "reference_number",
      align: "center",
      key: "reference_number",
    },
    {
      title: "Monto en Bs",
      dataIndex: "amount",
      align: "center",
      key: "amount",
      render: (_, record) => (
        <span>{numberFormatter(record.amount*record.usd_exchange_rate)}</span>
      ),
    },
    {
      title: "Monto en Drinkers",
      dataIndex: "amount",
      align: "center",
      key: "amount",
      render: (_, record) => (
        <span>{record.amount}</span>
      ),
    },
    {
      title: "Acciones",
      key: "action",
      width: 150,
      align: "center",
      render: (_, record) => (
        <div
          style={{
            display: "flex",
            gap: 2,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Tooltip title="Ver detalle">
            <EyeOutlined
              style={{ fontSize: 20, cursor: "pointer" }}
              onClick={() => {
                setIsVisible(true);
                setSelectedPayment(record);
              }}
            />
          </Tooltip>
          <Divider type="vertical" />
          <Tooltip title="Descargar comprobante">
            <DownloadOutlined
              style={{ fontSize: 20, cursor: "pointer" }}
              onClick={() => download(record.receipt)}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  useEffect(() => {
    getPayments();
  }, []);

  return (
    <Card>
      <Table
        className="gx-table-responsive"
        columns={columns}
        dataSource={payments}
        scroll={{ x: window.innerWidth < 1200 ? 1200 : false }}
        loading={isLoading}
      />
      <InvoiceDetail
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        data={selectedPayment}
      />
    </Card>
  );
};

export default Invoices;
