import React, { useEffect, useState } from "react";
import { 
  Card, 
  Tabs,
  InputNumber, 
  Form,
  Button,
} from "antd";
import { useSettings } from "../../context/settings/useSettings";

const Settings = () => {

  const [activeTab, setActiveTab] = useState("1");
  const [exchangeRate, setExchangeRate] = useState({
    ves_exchange_rate:0
  });

  const {
    isLoading,
    currenciesList,
    getCurrencies,
    updateCurrency,
  } = useSettings();

  const TabPane = Tabs.TabPane;
  const FormItem = Form.Item;
  const [form] = Form.useForm();

  const onSave = async () => {
    await form.validateFields().then((fields) => {
      setExchangeRate((prev) => ({...prev, ves_exchange_rate: fields.exchangeRate}));
      updateCurrency({currencyId: exchangeRate.id, payload: {ves_exchange_rate:fields.exchangeRate}})
    });
  };

  useEffect(()=>{
    getCurrencies();
  },[])

  useEffect(() => {
    if(currenciesList.length > 0){
      const usdRate = currenciesList.filter((curr)=> curr.iso_code ==="USD");
      setExchangeRate(usdRate[0]);
    }
  },[currenciesList])

  useEffect(() => {
    if (exchangeRate && exchangeRate.ves_exchange_rate) {
      form.setFieldsValue({
        exchangeRate: exchangeRate.ves_exchange_rate,
      });
    }
  }, [exchangeRate, form]);
  
  return (
    <Card>
      <Tabs
        defaultActiveKey="1"
        activeKey={activeTab}
        onChange={(activeKey) => setActiveTab(activeKey)}
      >
        <TabPane tab="Tasa de cambio" key="1" style>
          <div className="gx-mb-2">
            <Form
              form={form}
              layout="vertical"
            >
              <FormItem
                label="Cotizacion dollar"
                name="exchangeRate"
                rules={[
                  {
                    validator: (_, value) =>
                      value > 0 ? Promise.resolve() : Promise.reject(new Error('La tasa debe ser un número mayor a cero.')),
                  },
                ]}
              >
                <InputNumber 
                  formatter={value => `${value} bs`}
                  parser={value => value.replace('bs', '')}
                  placeholder="Tasa del día" 
                  style={{ maxWidth: 300, width: "100%" }} 
                  min={0.01}
                />
              </FormItem>
            </Form>

            <Button
              style={{ margin: "10px auto auto auto" }}
              onClick={onSave}
              loading={isLoading}
            >
              Guardar
            </Button>
          </div>
        </TabPane>
      </Tabs>
    </Card>
  );
};

export default Settings;
