import { Row } from "antd";
import React, { useEffect } from "react";
import ProfileHeader from "../../components/Profile/ProfileHeader";
import Auxiliary from "../../util/Auxiliary";
import About from "../../components/Profile/About/index";
import { useProfile } from "../../context/profile/useProfile";

export default function Profile() {
  const { getStore, getAccounts } = useProfile();

  useEffect(() => {
    getStore();
    getAccounts();
  }, []);

  return (
    <Auxiliary>
      <ProfileHeader />
      <div className="gx-profile-content">
        <Row style={{ display: "block" }}>
          <About />
        </Row>
      </div>
    </Auxiliary>
  );
}
