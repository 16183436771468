import React, { useEffect, useState } from "react";
import { Divider, Tooltip } from "antd";
import SimpleTable from "../../components/table/Basic/SimpleTable";
import DeleteModal from "../../components/Modal/DeleteModal";
import moment from "moment";
import { dateFormat } from "../../util/formatters";
import { useAuth } from "../../context/auth-context";
import { useUsers } from "../../context/Users/useUsers";
import ModalUser from "./ModalUser";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

const Users = () => {
  const [userSelected, setUserSelected] = useState("");
  const [action, setAction] = useState("");
  const { user } = useAuth();

  const {
    getUsers,
    usersList,
    isLoading,
    setModalVisible,
    deleteUser,
  } = useUsers();

  const handleOperatorDelete = (id) => {
    console.log(id);
    deleteUser(id);
  };
  const columns = [
    {
      title: "Fecha de creación",
      dataIndex: "created_at",
      key: "created_at",
      defaultSortOrder: "descend",
      sorter: (a, b) =>
        new moment(a.date).format("YYYYMMDDHHmmss") -
        new moment(b.date).format("YYYYMMDDHHmmss"),
      sortDirections: ["descend", "ascend"],
      render: (text, _) => {
        return <span>{dateFormat(text)}</span>;
      },
    },
    {
      title: "Correo electrónico",
      key: "email",
      dataIndex: "email",
      render: (_, record) => {
        return <span>{record.email}</span>;
      },
    },
    {
      title: "Nombre y apellido",
      key: "names",
      render: (_, record) => (
        <span>{`${record.first_name} ${record.last_name}`}</span>
      ),
    },
    {
      title: "Acciones",
      key: "action",
      width: 150,
      align: "center",
      render: (_, record) => (
        <div
          style={{
            display: "flex",
            gap: 2,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Tooltip title="Editar">
            <EditOutlined
              style={{ fontSize: 20, cursor: "pointer" }}
              onClick={() => {
                setUserSelected(record)
                setModalVisible(true);
                setAction("edit");
              }}
            />
          </Tooltip>
          <Divider type="vertical" />
          <Tooltip title="Eliminar">
            <DeleteOutlined
              style={{ fontSize: 20, cursor: "pointer" }}
              onClick={() =>
                record.id === user.id
                  ? null
                  : DeleteModal(record, "este usuario", handleOperatorDelete)
              }
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <>
      <SimpleTable
        columns={columns.map((col) => ({ ...col, align: "center" }))}
        data={usersList}
        buttonTitle="Nuevo usuario"
        onClickModal={() => {
          setModalVisible(true);
          setAction("create");
        }}
        isRolFilter={true}
        loading={isLoading}
      />

      <ModalUser
        action={action}
        userSelected={userSelected}
      />
    </>
  );
};

export default Users;
