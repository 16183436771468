import React, { useEffect, useState } from "react";
import { Menu } from "antd";
import { Link, useLocation } from "react-router-dom";
import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import Auxiliary from "util/Auxiliary";
import { THEME_TYPE_LITE } from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";
import { connect } from "react-redux";
import { useAuth } from "../../context/auth-context";

const SidebarContent = ({ themeType, toggleCollapsedSideNav }) => {
  const { logOut, isAdmin } = useAuth();
  const [activeItem, setActiveItem] = useState(window.location.pathname);
  const toggleCollapsedSideNavMobile = () => {
    if (toggleCollapsedSideNav) toggleCollapsedSideNav();
  };
  const location = useLocation();

  useEffect(() => {
    console.log(`/${location.pathname.split("/")[1]}`);
    setActiveItem(`/${location.pathname.split("/")[1]}`);
  }, [location.pathname]);

  const adminRoutes = [
    {
      key: "/stores",
      to: "/stores",
      labelId: "sidebar.stores",
      icon: "location",
    },
    {
      key: "/balances",
      to: "/balances",
      labelId: "sidebar.payable",
      icon: "revenue-new",
    },
    {
      key: "/movements",
      to: "/movements",
      labelId: "sidebar.movements",
      icon: "transfer",
    },
    {
      key: "/manage-products",
      to: "/manage-products",
      labelId: "sidebar.products",
      icon: "listing-dbrd",
    },
    { key: "/users", to: "/users", labelId: "sidebar.users", icon: "user" },
    {
      key: "/settings",
      to: "/settings",
      labelId: "sidebar.settings",
      icon: "setting",
    },
  ];

  const storeRoutes = [
    {
      key: "/products",
      to: "/products",
      labelId: "sidebar.products",
      icon: "listing-dbrd",
    },
    {
      key: "/promotions",
      to: "/promotions",
      labelId: "sidebar.promotions",
      icon: "widgets",
    },
    {
      key: "/invoices",
      to: "/invoices",
      labelId: "sidebar.invoices",
      icon: "sent",
    },
    {
      key: "/orders",
      to: "/orders",
      labelId: "sidebar.buying_history",
      icon: "product-list",
    },
    {
      key: "/profile",
      to: "/profile",
      labelId: "sidebar.profile",
      icon: "user-o",
    },
  ];

  const routes = isAdmin ? adminRoutes : storeRoutes;

  return (
    <Auxiliary>
      <SidebarLogo />
      <div className="gx-sidebar-content gx-h-100">
        <CustomScrollbars className="gx-layout-sider-scrollbar  gx-h-100 gx-layout-sider-scrollbar-fix">
          <Menu
            defaultOpenKeys="/productos"
            selectedKeys={activeItem}
            theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
            mode="inline"
            className=" gx-h-100 "
            onClick={(e) => {
              setActiveItem(e.key);
            }}
          >
            {routes.map(({ key, to, labelId, icon }) => (
              <Menu.Item key={key}>
                <Link
                  to={to}
                  onClick={toggleCollapsedSideNavMobile}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <i className={`icon icon-${icon}`} />
                  <IntlMessages id={labelId} />
                </Link>
              </Menu.Item>
            ))}

            <Menu.Item
              key="signin"
              style={{
                position: "absolute",
                bottom: 0,
                display: "flex",
                flexDirection: "row",
              }}
              onClick={() => {
                logOut();
              }}
            >
              <i className="icon icon-reply" />
              <Link to="/signin">Cerrar sesión</Link>
            </Menu.Item>
          </Menu>
        </CustomScrollbars>
      </div>
    </Auxiliary>
  );
};

SidebarContent.propTypes = {};
const mapStateToProps = ({ settings }) => {
  const { navStyle, themeType, locale, pathname } = settings;
  return { navStyle, themeType, locale, pathname };
};
export default connect(mapStateToProps)(SidebarContent);
