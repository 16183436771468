const useDownload = () => {
  const download = (url) => {
    var fileName = url.split("/");
    fetch(url)
      .then((res) => {
        return res.blob();
      })
      .then((blob) => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = url;
        a.download = fileName[5].split("?")[0];
        document.body.appendChild(a);
        a.click();
        setTimeout((_) => {
          window.URL.revokeObjectURL(url);
        }, 6000);
        a.remove();
      })
      .catch((err) => {
        console.error("err: ", err);
      });
  };
  return { download };
};

export { useDownload };
