import React from "react";
import { Table } from "antd";

const ChatUserTable = ({ data, showMessage = true }) => {
  const columns = [
    { title: "Nombre y apellido", dataIndex: "names", key: "names" },
    { title: "Sexo", dataIndex: "gender", key: "gender" },
    { title: "Teléfono", dataIndex: "phone", key: "phone" },
    { title: "Mensaje", dataIndex: "message", key: "message" },
  ];

  !showMessage && columns.pop();

  return (
    <Table
      columns={columns}
      dataSource={data}
      pagination={false}
      scroll={true}
      style={{ marginBottom: "10px" }}
    />
  );
};
export default ChatUserTable;
