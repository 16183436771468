import React, { createContext, useState } from "react";

const PurchasesContext = createContext({});

function PurchasesProvider({ children }) {
  const [isLoading, setIsLoading] = useState(false);
  const [purchasesList, setPurchasesList] = useState([]);
  const [selectedPurchase, setSelectedPurchase] = useState({});
  const [pagination, setPagination] = useState({
    current: 1,
    perPage: 15,
    total: 0,
  });

  return (
    <PurchasesContext.Provider
      value={{
        isLoading,
        setIsLoading,
        setPurchasesList,
        purchasesList,
        selectedPurchase,
        setSelectedPurchase,
        pagination, 
        setPagination,
      }}
    >
      {children}
    </PurchasesContext.Provider>
  );
}

export { PurchasesProvider, PurchasesContext };
