import React, { createContext, useContext, useState } from "react";

const NotificationContext = createContext();

function NotificationProvider({ children }) {
  const [notifications, setNotifications] = useState([]);

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        setNotifications,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
}

const useNotifications = () => {
  const { notifications, setNotifications } = useContext(NotificationContext);

  return {
    notifications,
    setNotifications,
  };
};

export { NotificationProvider, useNotifications };
