import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Typography, Select, Table } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import Dragger from "antd/lib/upload/Dragger";
import { usePayments } from "../../context/payment/usePayments";
import { useAuth } from "../../context/auth-context";
import { numberFormatter } from "../utils/formatters";
import { ReactComponent as DrinkerLogo } from "../../assets/images/drinker_small_logo.svg";

const { Paragraph } = Typography;
const UploadVoucher = ({
  action = "create",
  setIsVisible,
  isVisible,
  edit,
  store,
  amount,
  withPaymentTable = false,
  getTableData,
}) => {
  const [onError, setOnError] = useState("");
  const {
    modalLoading,
    postVoucherPayment,
    patchVoucherPayment,
    getAdminAccount,
    accountList,
    getPendingPayment,
    pendingPaymentData,
    createPendingPayment,
  } = usePayments();
  const { isAdmin } = useAuth();
  const [fileList, setFileList] = useState([]);
  const [accountOptions, setAccountOptions] = useState([]);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const [form] = Form.useForm();
  const FormItem = Form.Item;

  const onExpand = (expanded, record) => {
    if (expanded) {
      setExpandedRowKeys((prevKeys) => [...prevKeys, record.id]);
    } else {
      setExpandedRowKeys((prevKeys) => prevKeys.filter((key) => key !== record.id));
    }
  };

  const onSelectProduct = (value) => {
    form.setFieldsValue({ accountSelected: value })
  };

  const handleUpload = (values) => {
    if (fileList.length < 1) {
      setOnError("Por favor subir el comprobante de pago");
      return;
    }

    if(withPaymentTable){
      createPendingPayment({
        store,
        receipt: fileList[0],
        funds_account_origin: values.accountSelected,
      }, ).then((response) => {
        if (response && response.success) {
          getTableData();
        }
        setIsVisible(false);
      });
      return
    }

    if (edit) {
      patchVoucherPayment({
        store,
        receipt: fileList[0],
        account: values.accountSelected,
      }).then(() => setIsVisible(false));
    } else {
      postVoucherPayment({
        store,
        amount: String(amount),
        receipt: fileList[0],
        account: values.accountSelected,
      }).then(() => setIsVisible(false));
    }
  };

  const props = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file]);
      setOnError("");
      return false;
    },
    fileList,
  };

  const status = {
    CLAIMED:"Reclamado", 
    PENDING:"Pendiente",
    DELIVERED:"Entregado",
    REJECTED: "Rechazado",
  };

  const columns = [
    {
      title: "Estado",
      dataIndex: "status",
      align: "center",
      key: "status",
      render: (text) => {
        return <span>{status[text] || text}</span>
      }
    },
    {
      title: (
        <span>
          Monto en
          <DrinkerLogo height={13} style={{marginLeft:4, marginBottom:-2}}/>
        </span>
      ),
      dataIndex: "amount",
      align: "center",
      key: "amount",
      render: (text) => {
        return <span>{numberFormatter(text)}</span>
      }
    },
    {
      title: "Comisión",
      dataIndex: "commission_amount",
      align: "center",
      key: "commission_amount",
      render: (text) => {
        return <span>{numberFormatter(text)}</span>
      }
    },
  ];

  const productColumns = [
    {
      title: "Producto",
      dataIndex: "name",
      align: "center",
      key: "name",
    },
    {
      title: "Cantidad",
      dataIndex: "quantity",
      align: "center",
      key: "quantity",
    },
  ];
  const promotionColumns = [
    {
      title: "Promoción",
      dataIndex: "name",
      align: "center",
      key: "name",
    },
    {
      title: "Cantidad",
      dataIndex: "quantity",
      align: "center",
      key: "quantity",
    },
  ];

  const productList = (record) => {
    return (
      <>
        {record.products && record.products.length > 0 && (
          <Table
            dataSource={record.products}
            columns={productColumns}
            pagination={false}
            size="small"
          />
        )}
        {record.promotions && record.promotions.length > 0 && (
          <Table
            dataSource={record.promotions}
            columns={promotionColumns}
            pagination={false}
            size="small"
          />
        )}
      </>
    )
  }

  const checkExpandable = (record) => {
    if(record.products){
      return record.products.length > 0
    }
    if(record.promotions){
      return record.promotions.length > 0
    }
    return false
  };

  const DetailProducts = () => {
    if(withPaymentTable){
      return (
        <>
          <div className="custom-label">
            Ordenes
          </div>
          <Table
            rowKey="id"
            loading={modalLoading}
            className="gx-table-responsive custom-table-products"
            columns={columns}
            dataSource={pendingPaymentData}
            expandable={{
              expandedRowRender: (record, idx) => productList(record, idx),
              rowExpandable: (record) => checkExpandable(record),
              expandedRowKeys,
              onExpand,
            }}
            pagination={false}
            size="small"
          />
        </>
      )
    };
    return null;
  };

  useEffect(()=>{
    if(accountList.length > 0){
      const accounts = accountList.filter((el)=> el.name !== "Drinker" ).map((elem)=>({value:elem.id, label:elem.name}));
      setAccountOptions(accounts);
    }
  },[accountList])

  useEffect(()=>{
    if(isAdmin){
      getAdminAccount()
    } 
  },[])

  useEffect(() => {
    if(withPaymentTable && store){
      getPendingPayment(store)
    }
  },[store])

  useEffect(() => {
    form.resetFields();
    if (action !== "create") {
      form.setFieldsValue({
        name: "Promotion name",
        price: 8,
        description: "Promotion description",
      });
    }
  }, [action]);

  return (
    <Modal
      visible={isVisible}
      footer={null}
      onCancel={() => setIsVisible(false)}
      centered
      title="Adjunte el comprobante de pago"
    >
      <Form
        form={form}
        className="ant-advanced-search-form"
        layout="vertical"
        onFinish={(values) => handleUpload(values)}
      >
        <Dragger maxCount={1} {...props}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Haga clic o arrastre el archivo a esta área para cargarlo
          </p>
        </Dragger>
        <Paragraph type="danger" style={{ marginTop: 12 }}>
          {onError}
        </Paragraph>
        <FormItem
          name="accountSelected"
          label={`Cuenta bancaria`}
          rules={[
            {
              required: true,
              message: "Por favor seleccione una cuenta bancaria.",
            },
          ]}
        >
            <Select
              showSearch
              placeholder="Cuenta bancaria de origen"
              onChange={(value) =>
                onSelectProduct(value)
              }
              options={accountOptions}
              style={{width: "100%"}}
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            />
        </FormItem>
        <DetailProducts/>
        <div
          style={{
            justifyContent: "center",
            width: "100%",
            marginTop: 20,
            display: "flex",
          }}
        >
          <Button
            loading={modalLoading}
            htmlType="submit"
            type="primary"
          >
            {edit ? "Actualizar" : "Pagar"}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default UploadVoucher;
