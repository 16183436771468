import React, { createContext, useState } from "react";

const PaymentContext = createContext({});

function PaymentProvider({ children }) {
  const [isLoading, setIsLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [balance, setBalance] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    perPage: 15,
    total: 0,
  });
  const [payments, setPayments] = useState([]);
  const [selectedPayment, setSelectedPayment] = useState([]);
  const [invoiceList, setInvoiceList] = useState([]);
  const [accountList, setAccountList] = useState([]);
  const [pendingPaymentData, setPendingPaymentData] = useState([])
  const [paymentDetails, setPaymentDetails] = useState({});
  const [commisions, setCommissions] = useState({});

  return (
    <PaymentContext.Provider
      value={{
        isLoading,
        setIsLoading,
        modalLoading, 
        setModalLoading,
        balance,
        setBalance,
        payments,
        setPayments,
        selectedPayment,
        setSelectedPayment,
        pagination, 
        setPagination,
        invoiceList,
        setInvoiceList,
        accountList, 
        setAccountList,
        pendingPaymentData, 
        setPendingPaymentData,
        paymentDetails, 
        setPaymentDetails,
        commisions, 
        setCommissions,
      }}
    >
      {children}
    </PaymentContext.Provider>
  );
}

export { PaymentProvider, PaymentContext };
