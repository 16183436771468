import React, { createContext, useState } from "react";

const ProductsContext = createContext({});

function ProductsProvider({ children }) {
  const [isLoading, setIsLoading] = useState(false);
  const [productList, setProductList] = useState([]);
  const [promotionList, setPromotionList] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [pagination, setPagination] = useState({
    current: 1,
    perPage: 15,
    total: 0,
  });
  const [availableProducts, setAvailableproducts] = useState([]);

  return (
    <ProductsContext.Provider
      value={{
        isLoading,
        setIsLoading,
        productList,
        setProductList,
        selectedProduct,
        setSelectedProduct,
        promotionList,
        setPromotionList,
        pagination, 
        setPagination,
        availableProducts,
        setAvailableproducts,
      }}
    >
      {children}
    </ProductsContext.Provider>
  );
}

export { ProductsProvider, ProductsContext };
