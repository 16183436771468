import React, { useState, useEffect } from "react";
import { validatePassword } from "../../util/validators";
import { Button, Input, Form, message } from "antd";
import { Link } from "react-router-dom";
import withLoaderButton from "../../hocs/StyledButton";
const FormItem = Form.Item;
const RessetPassword = () => {
  const [isLoading] = useState(false);
  const [isNullField] = useState(false);
  const [errorsList] = useState([]);
  const LoaderButton = withLoaderButton(Button);
  const [form] = Form.useForm();
  useEffect(() => {
    form.setFields(errorsList);
    if (isNullField) message.error("Ocurrió un error al cambiar su contraseña");
  }, [errorsList, isNullField]);

  const onFinish = async (values) => {};
  const validatePasswordForm = (_, inputValue) => {
    if (inputValue) {
      const {
        minLength,
        maxLength,
        special,
        upper,
        lower,
        complete,
      } = validatePassword(inputValue);

      if (minLength && maxLength && special && upper && lower && complete) {
        return Promise.resolve();
      } else
        return Promise.reject(
          new Error(
            "Debe contener al menos una mayúscula, una minúscula, un caracter especial (!@#$%^&*,.) o número y minimo 8 caracteres"
          )
        );
    }
  };
  return (
    <div className="gx-login-container">
      <div className="gx-login-content">
        <div className="gx-login-header">
          <img
            src={require("assets/images/logo-white.png")}
            alt="wieldy"
            title="wieldy"
          />
        </div>
        <div className="gx-mb-4">
          <h2>Recuperar contraseña</h2>
        </div>

        <Form
          layout="vertical"
          onFinish={onFinish}
          className="gx-login-form gx-form-row0"
          form={form}
        >
          <FormItem
            name="password"
            rules={[
              {
                validator: validatePasswordForm,
                required: true,
              },
            ]}
            hasFeedback
          >
            <Input.Password type="password" placeholder="Contraseña" />
          </FormItem>
          <FormItem
            name="reset-password"
            rules={[
              {
                required: true,
                message: "Introduzca de nuevo su contraseña.",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("Las contraseñas no coinciden!")
                  );
                },
              }),
            ]}
          >
            <Input.Password
              type="password"
              placeholder="Repita la contraseña"
            />
          </FormItem>

          <FormItem className="gx-text-right ">
            <Button type="link">
              <Link to="/signin" className="gx-ml-2">
                Cancelar
              </Link>
            </Button>

            <LoaderButton isLoading={isLoading}>Enviar</LoaderButton>
          </FormItem>
        </Form>
      </div>
    </div>
  );
};

export default RessetPassword;
