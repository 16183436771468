import React from 'react';

export const UsdFormatter = ({price}) => {
  return (
    <strong>
      {Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(price)}
    </strong>
  )
}