import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-bottom: 8px;
`;

const Input = styled.input`
  width: 100%;
  max-width: 500px;
  height:36px;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  padding: 4px 11px;
  outline: none;
`;

const AutoComplete = ({ map, mapApi, addplace, location, disabled }) => {
  const searchInputRef = useRef(null);

  useEffect(() => {
    if (mapApi && mapApi.places) {
      const options = {};

      const autoComplete = new mapApi.places.Autocomplete(
        searchInputRef.current,
        options,
      );

      const onPlaceChanged = () => {
        const place = autoComplete.getPlace();

        if (!place.geometry) return;
        if (place.geometry.viewport) {
          map.fitBounds(place.geometry.viewport);
        } else {
          map.setCenter(place.geometry.location);
          map.setZoom(17);
        }

        addplace(place);
      };

      autoComplete.addListener('place_changed', onPlaceChanged);
      autoComplete.bindTo('bounds', map);

      if (location && location.lat && location.lng) {
        const geocoder = new mapApi.Geocoder();
        const latLng = new mapApi.LatLng(location.lat, location.lng);
        geocoder.geocode({ location: latLng }, (results, status) => {
          if (status === "OK") {
            if (results[0]) {
              const address = results[0].formatted_address;
              searchInputRef.current.value = address;
            } 
          }
        });
      }

      return () => {
        if (mapApi.event) {
          mapApi.event.clearInstanceListeners(searchInputRef.current);
        }
      };
    }
  }, [map, mapApi, addplace, location]);

  return (
    <Wrapper>
      <Input
        ref={searchInputRef}
        type="text"
        placeholder="Buscar ubicación"
        disabled={disabled}
      />
    </Wrapper>
  );
};

export default AutoComplete;