import React, { useEffect, useState } from "react";
import { Card, Table, Tag, Pagination } from "antd";
import "moment/locale/es";
import moment from "moment";
import { dateFormat, numberFormatter } from "../utils/formatters";
import { useAuth } from "../../context/auth-context";
import OrderDetail from "./OrderDetail";
import { usePurchase } from "../../context/purchases/usePurchase";
import { filterDropdown } from "../utils/tableFilters";
import { ReactComponent as DrinkerLogo } from "../../assets/images/drinker_small_logo.svg";

const Orders = () => {
  const { isAdmin } = useAuth();
  const [canBeDispatched] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [action] = useState("");
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [tableFilters, setTableFilters] = useState({});
  const [currentPage, setCurrentPage] = useState(1);

  const {
    getPurchases,
    isLoading,
    purchasesList,
    pagination,
  } = usePurchase();

  const status = {
    CLAIMED:{label:"QR Escaneado", color:"green"}, 
    ACCEPTED: {label:"Aceptado", color:"blue"},
    DELIVERED:{label:"Entregado", color:"green"},
  };

  const onExpand = (expanded, record) => {
    if (expanded) {
      setExpandedRowKeys((prevKeys) => [...prevKeys, record.id]);
    } else {
      setExpandedRowKeys((prevKeys) => prevKeys.filter((key) => key !== record.id));
    }
  };

  const onPagination = (page) => {
    setCurrentPage(page);
  };

  const onChangeFilter = (_, filters) => {
    setCurrentPage(1);
    setTableFilters((prevFilter) => ({ ...prevFilter, ...filters }));
  };

  const getCommission = (record) => {
    if(record.refund_info){
      return numberFormatter(record.refund_info.fee);
    }
    return numberFormatter(record.commission_amount);
  };

  const getStatus = (record) => {
    if(record.gift_has_expired){
      return (
        <Tag
          className="gx-text-uppercase"
          color={"red"}
          style={{ marginBottom: 0 }}
        >
          Vencido
        </Tag>
      )
    } 
    if(record.store_payment){
      return (
        <Tag
          className="gx-text-uppercase"
          color={"#1D1B86"}
          style={{ marginBottom: 0, marginRight: 0 }}
        >
          Pagado
        </Tag>
      )
    } 
    return (
      <Tag
        className="gx-text-uppercase"
        color={status[record.status].color}
        style={{ marginBottom: 0 }}
      >
        {status[record.status].label}
      </Tag>
    )
  };

  const getClientName = (record) => {
    if(record.gift_recipient_info){
      return record.gift_recipient_info.profile.name;
    }
    return record.client_name;
  };

  const columns = [
    {
      title: "Fecha ",
      width: 120,
      dataIndex: "created_at",
      key: "created_at",
      defaultSortOrder: "descend",
      sorter: (a, b) =>
        new moment(a.created_at).format("YYYYMMDDHHmmss") -
        new moment(b.created_at).format("YYYYMMDDHHmmss"),
      sortDirections: ["descend", "ascend"],
      render: (text, _) => {
        return <span>{dateFormat(text)}</span>;
      },
    },
    {
      title: "Número de orden",
      dataIndex: "reference_number",
      key: "reference_number",
    },
    ...(isAdmin
      ? [
          {
            title: "Bar",
            dataIndex: "store_name",
            key: "store_name",
          },
        ]
      : []),
    {
      title: "Nombre del cliente",
      dataIndex: "client_name",
      key: "client_name",
      render: (_, record) => (
        <span>
          {getClientName(record)}
        </span>
      ),
    },
    {
      title: (
        <span>
          Monto en
          <DrinkerLogo height={13} style={{marginLeft:4, marginBottom:-2}}/>
        </span>
      ),
      key: "amount",
      align: "center",
      render: (_, record) => (
        <span>
          {numberFormatter(record.amount)}
        </span>
      ),
    },
    {
      title: "Comisión",
      dataIndex: "refund_info",
      align: "center",
      key: "refund_info",
      render: (_, record) => (
        <span>{getCommission(record)}</span>
      ),
    },
    {
      title: "Plazo de retiro",
      dataIndex: "days_before_gift_expiration",
      align: "center",
      key: "days_before_gift_expiration",
      render: (text) => (
        <span>{text} Días</span>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 100,
      align: "center",
      filterDropdown: (props) => {
        const options = Object.entries(status).map(([value, { label }]) => ({
          label,
          value,
        }));
        const {setSelectedKeys, selectedKeys, confirm, clearFilters} = props;
        return filterDropdown(options, setSelectedKeys, selectedKeys, confirm, clearFilters);
      },
      
      render: (_, record) => getStatus(record),
    },
  ];

  const productColumns = [
    {
      title: "Producto",
      key: "name",
      align: "center",
      dataIndex: "name"
    },{
      title: "Cantidad",
      key: "quantity",
      align: "center",
      dataIndex: "quantity",
    },{
      title: (
        <span>
          Precio en
          <DrinkerLogo height={13} style={{marginLeft:4, marginBottom:-2}}/>
        </span>
      ),
      key: "price",
      align: "center",
      render: (_, record) => (
        <span>
          {numberFormatter(record.price)}
        </span>
      ),
    },
  ]

  const productList = (products) => {
    return (
      <>
        <Table
          dataSource={products}
          columns={productColumns}
          pagination={false}
          size="small"
        />
      </>
    )
  }

  useEffect(() => {
    if(tableFilters && Object.keys(tableFilters).length > 0){
      const transformedFilters = {};
      Object.entries(tableFilters).forEach(([key, value]) => {
        if (Array.isArray(value) && value.length > 0) {
          transformedFilters[key] = value[0];
        } else {
          transformedFilters[key] = value;
        }
      });
      getPurchases(currentPage, transformedFilters);
    } else {
      getPurchases(currentPage, {})
    }
  }, [currentPage, tableFilters]);

  return (
    <Card>
      <Table
        rowKey="id"
        loading={isLoading}
        className="gx-table-responsive"
        columns={columns.map((col) => ({ ...col, align: "center" }))}
        dataSource={purchasesList && purchasesList.reverse()}
        onChange={onChangeFilter}
        pagination={false}
        expandable={{
          expandedRowRender: (record) => productList(record.products),
          rowExpandable: (record) => record.products.length > 0,
          expandedRowKeys,
          onExpand,
        }}
      />
      <Pagination
        current={currentPage}
        pageSize={pagination.perPage}
        total={pagination.total}
        onChange={(page) => onPagination(page)}
        style={{textAlign: "end", marginTop: "10px"}}
        showSizeChanger={false}
      />
      <OrderDetail
        action={action}
        isVisible={isVisible}
        setIsVisible={(data) => setIsVisible(data)}
        canBeDispatched={canBeDispatched}
        // allUsers={allUsers}
        // setAllUsers={(data) => setAllUsers(data)}
      />
    </Card>
  );
};

export default Orders;
