import React, { useEffect, useState } from "react";
import { 
    Divider, 
    Tooltip,
    Row,
    Col,
    Input,
    Table,
    Pagination,
    Card,
    Button,
} from "antd";
import ModalOperator from "./ModalProducts";
import DeleteModal from "../../components/Modal/DeleteModal";
import moment from "moment";
import { dateFormat } from "../../util/formatters";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useProducts } from "../../context/products/useProducts";

const ManageProducts = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [tableFilters, setTableFilters] = useState({});
  const [enterPressed, setEnterPressed] = useState(false);
  const [tablaData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const {
    getAdminProducts,
    isLoading,
    productList,
    setSelectedProduct,
    pagination,
    deleteAdminProduct,
  } = useProducts();

  const [action, setAction] = useState("");

  const handleOperatorDelete = async (id) => {
    await deleteAdminProduct({productId: id})
  };

  const onPagination = (page) => {
    setCurrentPage(page);
  };

  const onChangeSearch = (name) => {
    setCurrentPage(1);
    setTableFilters((prevFilter) => ({ ...prevFilter, name: name }));
  };

  const handleEnterPress = (search) => {
    onChangeSearch(search);
    setEnterPressed(true);
  };

  const handleBlur = (search) => {
    if (!enterPressed) {
      onChangeSearch(search);
    }
    setEnterPressed(false);
  };

  const columns = [
    {
      title: "Fecha de creación",
      dataIndex: "date",
      key: "date",
      defaultSortOrder: "descend",
      align: "center",
      sorter: (a, b) =>
        new moment(a.date).format("YYYYMMDDHHmmss") -
        new moment(b.date).format("YYYYMMDDHHmmss"),
      sortDirections: ["descend", "ascend"],
      render: (_, record) => {
        return <span>{dateFormat(record.created_at)}</span>;
      },
    },
    {
      title: "Nombre",
      key: "name",
      dataIndex: "name",
      align: "center",
      render: (_, record) => {
        return <span>{record.name}</span>;
      },
    },
    {
      title: "Imagen",
      key: "photo",
      dataIndex: "photo",
      align: "center",
      render: (_, record) => (
        <img
          src={record.photo}
          alt="Imagen"
          title="Imagen"
          style={{ maxWidth: 56, maxHeight: 56 }}
        />
      ),
    },
    {
      title: "Acciones",
      key: "action",
      width: 150,
      align: "center",
      render: (_, record) => (
        <div
          style={{
            display: "flex",
            gap: 2,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Tooltip title="Editar">
            <EditOutlined
              style={{ fontSize: 20, cursor: "pointer" }}
              onClick={() => {
                setAction("edit");
                setIsVisible(true);
                setSelectedProduct(record);
              }}
            />
          </Tooltip>
          <Divider type="vertical" />
          <Tooltip title="Eliminar">
            <DeleteOutlined
              style={{ fontSize: 20, cursor: "pointer" }}
              onClick={() =>
                DeleteModal(record, "este producto", () =>
                  handleOperatorDelete(record.id)
                )
              }
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  useEffect(() => {
    if(tableFilters && Object.keys(tableFilters).length > 0){
      const transformedFilters = {};
      Object.entries(tableFilters).forEach(([key, value]) => {
        if (Array.isArray(value) && value.length > 0) {
          transformedFilters[key] = value;
        } else {
          transformedFilters[key] = value;
        }
      });
      getAdminProducts({page:currentPage, filters:transformedFilters});
    } else {
      getAdminProducts({page:currentPage})
    }
  }, [currentPage, tableFilters]);

  useEffect(()=>{
    const mappedData = productList.map((elem)=>{
      const productData = {
        id: elem.id,
        created_at: elem.created_at,
        name: elem.name,
        photo: elem.photo,
      }
      return productData
    })
    setTableData(mappedData);
  },[productList])

  return (
    <>
    <Card>
      <Row className="gx-justify-content-end gx-mx-0 gx-mb-3">
        <Col>
          <Input
            placeholder="Nombre del producto"
            style={{ width: 250 }}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                handleEnterPress(event.target.value);
              }
            }}
            onBlur={(event) => handleBlur(event.target.value)}
          />
        </Col>
        <Col className="gx-ml-3">
            <Button 
              type="primary" 
              style={{width: 100, margin:0}} 
              onClick={() => {
                setIsVisible(true);
                setAction("create");
              }}
            >
              Agregar
            </Button>
        </Col>
      </Row>
      <Table
        className="gx-table-responsive"
        loading={isLoading}
        columns={columns}
        dataSource={tablaData}
        scroll={{ x: window.innerWidth < 1200 ? 1200 : false }}
        pagination={false}
      />
      <Pagination
        current={pagination.current}
        pageSize={pagination.perPage}
        total={pagination.total}
        onChange={(page) => onPagination(page)}
        style={{textAlign: "end", marginTop: "10px"}}
        showSizeChanger={false}
      />
      <ModalOperator
        action={action}
        isVisible={isVisible}
        setIsVisible={(data) => setIsVisible(data)}
      />
    </Card>
    </>
  );
};

export default ManageProducts;
